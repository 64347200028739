import { GRID_TREE_DATA_GROUPING_FIELD, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import {
  outputOf,
  sampleId,
  stGridSampleAvailability,
  stGridSampleSampleTypeId,
  stGridTransitionSampleTransitionId,
} from '../../util/Constants';
import { Sample } from '../../data/SampleData';
import { SampleJourneyTransitionInformation, SampleJourneyTree } from '../../data/SampleJourneyData';
import { makeStyles } from '@mui/styles';
import { keyBy } from 'lodash';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { Box } from '@mui/material';
import { GridCellCallBack } from '../../components/grid/GridCellCallback';
import { Dictionary, UseStateSetter } from '../../util/TypeUtil';
import { sampleTrackingGridDefaultColumnOverrides } from '../../util/grid/TableUtils';
import { SampleTrackingGridWrapper } from '../../components/grid/SampleTrackingGridWrapper';
import { LoadingProps } from '../../components/LoadingStateUtil';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';
import { TextOverflowBox } from 'components/TextOverflowBox';

export interface SampleJourneyHierarchyGridProps {
  identifiersBySampleId: Dictionary<string>;
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>;
  hierarchyTreeData: ReadonlyArray<SampleJourneyTree>;
  selectedSampleIds: ReadonlyArray<string>;
  setSelectedSampleIds: UseStateSetter<ReadonlyArray<string>>;
  configuredTransitions: ReadonlyArray<ConfiguredTransition>;
  loadingProps: LoadingProps;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
}

export type Row = Omit<SampleJourneyTree, 'descendant'> & {
  id: number;
  path: string[];
  qualityCheckStatus?: string;
  outputOf: string;
  currentStage: string;
} & Sample &
  SampleJourneyTransitionInformation;

const useStyles = makeStyles({
  highlightedRow: {
    backgroundColor: 'rgb(231, 221, 255)',
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'inherit !important',
      pointerEvents: 'none',
    },
  },
});

export const SampleJourneyHierarchyGrid = ({
  identifiersBySampleId,
  transitionInformation,
  hierarchyTreeData,
  selectedSampleIds,
  setSelectedSampleIds,
  configuredTransitions,
  loadingProps,
  leftActions,
  rightActions,
}: SampleJourneyHierarchyGridProps) => {
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();
  const classes = useStyles();

  const columns = useColumns();
  const rows = useRows(hierarchyTreeData, transitionInformation, configuredTransitions);

  return (
    <SampleTrackingGridWrapper
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: sampleId, sort: 'asc' }],
        },
      }}
      getTreeDataPath={(row: Row) => row?.path}
      treeData
      groupingColDef={{
        headerAlign: 'left',
        headerName: t('sampleIdentifier'),
        minWidth: 500,
        hideDescendantCount: true,
        cellClassName: 'monospace-font',
        renderCell: params => {
          const { row, rowNode, value } = params;
          const sampleId = row.sampleId;

          return (
            <Box sx={{ ml: rowNode.depth * 4, fontFamily: 'monospace', width: 'inherit' }}>
              {selectedSampleIds.includes(sampleId) ? (
                <TextOverflowBox value={value} />
              ) : (
                <GridCellCallBack
                  text={value}
                  callBack={() => {
                    setSelectedSampleIds([sampleId]);
                  }}
                  sx={{ paddingLeft: 0, minWidth: 0, fontFamily: 'monospace' }}
                />
              )}
            </Box>
          );
        },
        valueGetter: params => identifiersBySampleId[params?.row?.sampleId ?? ''] ?? '',
      }}
      defaultGroupingExpansionDepth={100}
      disableRowSelectionOnClick
      getRowClassName={params =>
        selectedSampleIds && selectedSampleIds.includes(params.row.sampleId) ? classes.highlightedRow : classes.noHover
      }
      leftActions={leftActions}
      rightActions={rightActions}
      loadingProps={loadingProps}
      hideLoadingIndicator
      defaultColumnOrdering={[
        GRID_TREE_DATA_GROUPING_FIELD,
        outputOf,
        stGridTransitionSampleTransitionId,
        stGridSampleSampleTypeId,
        stGridSampleAvailability,
      ]}
      cacheKey={'sample-tracking-journey-hierarchy-grid'}
      columnDefinitionOverrides={sampleTrackingGridDefaultColumnOverrides}
    />
  );
};

const useRows = (
  trees: ReadonlyArray<SampleJourneyTree>,
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>,
  configuredTransitions: ReadonlyArray<ConfiguredTransition>
): ReadonlyArray<Row> => {
  return useMemo(() => {
    const informationBySampleId = keyBy(transitionInformation, t => t.sampleId);
    const transitionById = keyBy(configuredTransitions, i => i.configuredTransitionId);

    let id = 0;

    function getNodes(tree: SampleJourneyTree) {
      const information = informationBySampleId[tree.ancestor.sampleId] ?? {};

      const nodes: Row[] = [
        {
          id: id++,
          ancestor: tree.ancestor,
          depth: tree.depth,
          path: tree.path,
          isLeaf: tree.isLeaf,
          ...tree.ancestor,
          ...information,
          outputOf: transitionById[information?.transitionCreatedIn?.configuredTransitionId ?? '']?.displayName,
          currentStage: transitionById[information?.currentTransition?.configuredTransitionId ?? '']?.displayName,
        },
      ];

      for (const child of tree.descendant) {
        nodes.push(...getNodes(child));
      }

      nodes.forEach(node => (node.id = id++));

      return nodes;
    }

    return trees.flatMap(tree => getNodes(tree));
  }, [trees, transitionInformation, configuredTransitions]);
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: outputOf,
        headerName: t(outputOf),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
    ],
    [t]
  );
};
