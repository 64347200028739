import { appSettings } from '../AppSettings';
import { BaseDeleteVoid, BaseDownloadFile, BaseGet, BasePost } from './BaseData';
import { SampleSequencingJourneyDetails } from './SampleJourneyData';
import {
  LabAssignedSampleLabel,
  SampleTrackingTableData,
  TransitionSample,
  TransitionType,
} from './SampleTrackingData';
import { ResearchProject } from './ResearchProjectData';

export type TransitionDto = Omit<Partial<Transition>, 'createdAt'> & {
  researchProjectId: string;
  transitionId?: string;
  transitionEnumId?: string;
  transition?: string;
  orderedAt: string;
  createdAt?: string;
  configuredTransitionId: string;
};

export interface Transition {
  transitionId: string;
  transitionEnumId: string;
  transition: string;
  createdAt: Date;
  orderedAt: string;
  configuredTransitionId: string;
}

export interface TransitionShippingDetails extends TransitionDto {
  comment?: string;
  destinationStorageTemperatureId?: string;
  minimumSampleVolume?: number;
  minimumSampleVolumeUnitId?: string;
  primaryShippingContainerId?: string;
  receiverOrganizationId: string;
  senderOrganizationId: string;
  shipByDate?: string;
  shippingCarrierId?: string;
  shippingStorageTemperatureId?: string;
  trackingNumber?: string;
}

export interface TransitionReceivingDetails extends TransitionDto {
  comment?: string;
  destinationStorageTemperatureId?: string;
  receiverOrganizationId: string;
  senderOrganizationId: string;
  receivingCarrierId?: string;
  shippingStorageTemperatureId?: string;
  trackingNumber?: string;
  receivingDate?: string;
}

export interface TransitionSubsettingDetails extends TransitionDto {
  transitionSubsettingDetailsId?: string;
  organizationId: string;
  subsettingType: string;
}

export interface TransitionExtractionDetails extends TransitionDto {
  transitionExtractionDetailsId?: string;
  organizationId: string;
  extractionType: string;
  extractionPlatformId: string;
  extractionKitId: string;
}

export interface TransitionQuantificationDetails extends TransitionDto {
  organizationId: string;
}

export interface TransitionLibraryPrepDetails extends TransitionDto {
  transitionLibraryPrepDetailsId?: string;
  organizationId: string;
  libraryKitId: string;
}

export interface TransitionSequencingDetails extends TransitionDto {
  transitionSequencingDetailsId?: string;
  organizationId: string;
  sequencingExpectedCoverage: string;
  sequencingPlatformId: string;
}

export interface TransitionInformaticsDetails extends TransitionDto {
  sampleJourneyDetails: ReadonlyArray<SampleSequencingJourneyDetails>;
  pipelineId: string;
  referenceTypeIds: ReadonlyArray<number>;
}

export type TransitionDetails =
  | TransitionShippingDetails
  | TransitionReceivingDetails
  | TransitionSubsettingDetails
  | TransitionExtractionDetails
  | TransitionLibraryPrepDetails
  | TransitionSequencingDetails
  | TransitionInformaticsDetails;

export interface TransitionDetailsPayload {
  shippingDetails: ReadonlyArray<TransitionShippingDetails>;
  receivingDetails: ReadonlyArray<TransitionReceivingDetails>;
  subsettingDetails: ReadonlyArray<TransitionSubsettingDetails>;
  extractionDetails: ReadonlyArray<TransitionExtractionDetails>;
  libraryPrepDetails: ReadonlyArray<TransitionLibraryPrepDetails>;
  sequencingDetails: ReadonlyArray<TransitionSequencingDetails>;
  informaticsDetails: ReadonlyArray<TransitionInformaticsDetails>;
}

export interface TransitionSampleDetailsDto {
  researchProject: ResearchProject;
  transition: Transition;
  transitionSample: TransitionSample;
  labAssignedSampleLabel?: LabAssignedSampleLabel;
}

export interface TransitionCounts {
  transitionId: string;
  transitionSampleCounts: number;
  supplementalFileCounts: number;
}

export async function CreateSampleTrackingShippingTransition(
  detailsDto: TransitionShippingDetails,
  sampleIds: ReadonlyArray<string>,
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Shipping`,
    {
      shippingDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}
export async function CreateSampleTrackingReceivingTransition(
  detailsDto: TransitionReceivingDetails,
  sampleIds: ReadonlyArray<string>,
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Receiving`,
    {
      receivingDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingSubsettingTransition(
  detailsDto: TransitionSubsettingDetails,
  sampleIds: string[],
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Subsetting`,
    {
      subsettingDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingExtractionTransition(
  detailsDto: TransitionExtractionDetails,
  sampleIds: string[],
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Extraction`,
    {
      extractionDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingQuantificationTransition(
  detailsDto: TransitionQuantificationDetails,
  sampleIds: string[],
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Quantification`,
    {
      quantificationDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingLibraryPrepTransition(
  detailsDto: TransitionLibraryPrepDetails,
  sampleIds: string[],
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/LibraryPrep`,
    {
      libraryPrepDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingSequencingTransition(
  detailsDto: TransitionSequencingDetails,
  sampleIds: string[],
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Sequencing`,
    {
      sequencingDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingInformaticsTransition(
  detailsDto: TransitionInformaticsDetails,
  sampleIds: string[],
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Informatics`,
    {
      informaticsDetailsDto: detailsDto,
      sampleIdsToTransition: sampleIds,
    },
    accessToken
  );
}

export async function CreateSampleTrackingTransition(
  dto: TransitionDto,
  sampleIds: string[],
  transitionEnum: TransitionType,
  accessToken: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition`,
    {
      transitionDto: dto,
      sampleIdsToTransition: sampleIds,
      transitionEnum: transitionEnum,
    },
    accessToken
  );
}

export async function GetSampleTrackingTransitions(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<Transition>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/${researchProjectId}`, accessToken);
}

export async function ExportTransitionManifest(
  researchProjectId: string,
  transitionId: string,
  accessToken?: string
): Promise<void> {
  await BaseDownloadFile(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingManifest/${researchProjectId}/${transitionId}`,
    accessToken
  );
}

export async function GetSampleTrackingTransitionDetails(
  researchProjectId: string,
  accessToken?: string
): Promise<TransitionDetailsPayload> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Details/${researchProjectId}`,
    accessToken
  );
}

export async function GetTransitionCounts(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<TransitionCounts>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingTransition/Counts/${researchProjectId}`,
    accessToken
  );
}

export async function PostUndoTransition(transitionId: string, accessToken?: string): Promise<void> {
  await BaseDeleteVoid(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/Transition/${transitionId}`,
    accessToken
  );
}

export async function PostUndoQualityCheckGroup(qualityCheckGroupId: string, accessToken?: string): Promise<void> {
  await BaseDeleteVoid(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/QualityCheckGroup/${qualityCheckGroupId}`,
    accessToken
  );
}

export async function GetUndoableTransitionIds(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<string>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/Undoable/Transition/${researchProjectId}`,
    accessToken
  );
}

export async function GetOrderScopedSampleTrackingTableData(
  transitionId: string,
  researchProjectId?: string,
  decrypt?: boolean,
  accessToken?: string
): Promise<ReadonlyArray<SampleTrackingTableData>> {
  const url = !researchProjectId
    ? `${appSettings.api.endpoint}/api/v2/SampleTracking/TransitionSamples/${transitionId}?decrypt=${decrypt}`
    : `${appSettings.api.endpoint}/api/v2/SampleTracking/TransitionSamples/${transitionId}?researchProjectId=${researchProjectId}&decrypt=${decrypt}`;

  return await BaseGet(url, accessToken);
}
