import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetDataClasses, SuperDuperFiestaDataClass } from '../../data/SuperDuperFiestaData';

const useSuperDuperFiestaDataClasses = (): ReadonlyArray<SuperDuperFiestaDataClass> => {
  const { accessToken } = useAuth();
  const [SuperDuperFiestaDataClasses, setSuperDuperFiestaDataClasses] = useState<
    ReadonlyArray<SuperDuperFiestaDataClass>
  >([]);

  useEffect(() => {
    let isCancelled = false;

    GetDataClasses(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setSuperDuperFiestaDataClasses(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return SuperDuperFiestaDataClasses;
};

export default useSuperDuperFiestaDataClasses;
