import React, { useCallback, useEffect, useState } from 'react';
import {
  getPatientJourneyEntityDisplayName,
  PATIENT_JOURNEY_ENTITIES,
  PatientApprovalPatientData,
  PatientJourneyEntity,
  PatientJourneyRow,
} from '../../../../data/PatientApprovalData';
import { Box, Container, Stack, styled, TextField, useTheme } from '@mui/material';
import {
  DataGridProProps,
  gridClasses,
  GridColDef,
  gridFilterModelSelector,
  GridRowClassNameParams,
  GridSortModel,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import EntityFilterBar from './EntityFilterBar';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { CompactGridWrapper } from '../../../../components/grid/CompactGridWrapper';
import ShowDataWithIssuesButton from '../common/ShowDataWithIssuesButton';
import { usePatient, useShowDataWithIssues } from '../../stores/dataStore';
import assertExhaustive from '../../utils/assertExhaustive';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';

type DirectCol = Exclude<GridColDef, 'renderCell'> & { field: keyof PatientJourneyRow };
type ComputedCol = GridColDef & { field: string } & {
  valueGetter: (params: GridValueGetterParams<PatientJourneyRow>) => string;
};
type PatientJourneyGridColDef = DirectCol | ComputedCol;

export type EntitySelection = 'All' | PatientJourneyEntity;

const PatientJourneyGroup: React.FC<{ patientData: PatientApprovalPatientData | undefined }> = ({ patientData }) => {
  const theme = useTheme();
  const gridApiRef = useGridApiRef();

  const [selectedEntity, setSelectedEntity] = useState<EntitySelection | null>('All');
  const [searchText, setSearchText] = useState<string | null>(null);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'date', sort: 'asc' }]);

  const onEntitySelectionChanged = useCallback(
    (entity: EntitySelection) => {
      if (!gridApiRef.current) {
        return;
      }

      setSelectedEntity(entity);

      const currentFilterModel = gridFilterModelSelector(gridApiRef);

      const newItems = currentFilterModel.items.filter(item => item.field !== 'entity');
      if (entity !== null && entity !== 'All') {
        newItems.push({ field: 'entity', operator: 'is', value: entity });
      }

      setFilterModel(existing => ({
        ...existing,
        items: newItems,
      }));
    },
    [gridApiRef]
  );

  const onSearchTextChanged = useCallback(
    (searchText: string | null) => {
      if (!gridApiRef.current) {
        return;
      }

      setSearchText(searchText);

      const searchTerms = (searchText?.split(' ') ?? []).filter(term => term.length > 0);

      setFilterModel(existing => ({
        ...existing,
        quickFilterValues: searchTerms,
      }));
    },
    [gridApiRef]
  );

  const updateSelectedEntityOnFilterChange = (filterModel: GridFilterModel) => {
    const entityFilters = filterModel.items.filter(item => item.field === 'entity');

    if (entityFilters.length === 0) {
      setSelectedEntity('All');
    } else if (entityFilters.length === 1) {
      const entityFilter = entityFilters[0];

      if (entityFilter.operator === 'is') {
        const filteredEntity = entityFilter.value as PatientJourneyEntity | undefined;

        if (filteredEntity === undefined) {
          setSelectedEntity('All');
        } else {
          setSelectedEntity(filteredEntity);
        }
      }
    } else {
      // Multiple entity filters or non-'is' operator, multiple entities may be shown in the table
      setSelectedEntity(null);
    }
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    setFilterModel(filterModel);
    updateSelectedEntityOnFilterChange(filterModel);
  };

  // Reset the table state when the patient changes
  const patient = usePatient();
  useEffect(() => {
    onEntitySelectionChanged('All');
    onSearchTextChanged(null);
    setFilterModel({ items: [] });
    setSortModel([{ field: 'date', sort: 'asc' }]);
  }, [patient, onEntitySelectionChanged, onSearchTextChanged]);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel({
        items: [],
      });
    }
  }, [patientData, gridApiRef]);

  const showDataWithIssues = useShowDataWithIssues();
  const [rows, setRows] = useState<PatientJourneyRow[]>([]);
  useEffect(() => {
    if (!patientData) {
      return;
    }

    switch (showDataWithIssues) {
      case 'noIssues':
        setRows(patientData.patientJourney.filter(row => row.issues.length === 0));
        break;
      case 'onlyIssues':
        setRows(patientData.patientJourney.filter(row => row.issues.length > 0));
        break;
      case 'all':
        setRows(patientData.patientJourney);
        break;
      default:
        assertExhaustive(showDataWithIssues);
        throw new Error('Unreachable');
    }
  }, [patientData, showDataWithIssues]);

  const columns: PatientJourneyGridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      editable: false,
      valueGetter: (params: GridValueGetterParams<PatientJourneyRow>) => new Date(params.row.date),
      type: 'date',
      width: 100,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: 'entity',
      headerName: 'Entity',
      editable: false,
      type: 'singleSelect',
      width: 125,
      valueOptions: PATIENT_JOURNEY_ENTITIES.map(e => ({ value: e, label: e })),
      getApplyQuickFilterFn: undefined,
      renderCell: ({ value }: GridRenderCellParams<PatientJourneyRow, PatientJourneyEntity>) =>
        value === undefined ? undefined : getPatientJourneyEntityDisplayName(value),
    },
    {
      field: 'vocabulary',
      headerName: 'Vocab',
      editable: false,
      type: 'string',
      width: 100,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: 'vocabularyCode',
      headerName: 'Vocab Code',
      editable: false,
      filterable: false,
      type: 'string',
      width: 125,
    },
    { field: 'name', headerName: 'Name', editable: false, filterable: false, type: 'string', flex: 1 },
    { field: 'result', headerName: 'Result', editable: false, filterable: false, type: 'string', flex: 1 },
  ];

  return (
    <Stack flex={1} direction='column' spacing={1}>
      <Stack flex={0} direction='row' alignItems='center' spacing={1}>
        <Box flex={1}>
          <EntityFilterBar selectedEntity={selectedEntity} onEntitySelected={onEntitySelectionChanged} />
        </Box>

        <Box flex={1}>
          <TextField
            label='Search (Code or Name)'
            size='small'
            fullWidth
            variant='outlined'
            sx={{ backgroundColor: theme.palette.background.paper }}
            value={searchText ?? ''}
            onChange={e => onSearchTextChanged(e.target.value)}
          />
        </Box>

        <Box flex={0}>
          <ShowDataWithIssuesButton />
        </Box>
      </Stack>

      <Stack
        direction='column'
        spacing={1}
        justifyContent='center'
        alignItems='stretch'
        flex={1}
        sx={{ color: theme.palette.text.primary, position: 'relative' }}
      >
        <Container
          maxWidth={false}
          disableGutters={true}
          sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
        >
          <PatientJourneyGrid
            apiRef={gridApiRef}
            columns={columns}
            rows={rows ?? []}
            getRowId={(row: PatientJourneyRow) => row.id}
            rowSelection={false}
            hideFooter={false}
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            sx={{ maxHeight: '100%' }}
            getRowHeight={() => 'auto'}
            getRowClassName={(params: GridRowClassNameParams<PatientJourneyRow>) =>
              `patient-journey-grid-${params.row.issues.length > 0 ? 'HasIssues' : 'NoIssues'}`
            }
          />
        </Container>
      </Stack>
    </Stack>
  );
};

const PatientJourneyGrid: React.FC<DataGridProProps> = styled(CompactGridWrapper)(({ theme }) => ({
  '& .patient-journey-grid-HasIssues': {
    backgroundColor: theme.palette.grey.A200,
  },

  [`& .${gridClasses.cell}`]: {
    'white-space': 'nowrap',
    'align-items': 'flex-start',
  },

  [`& .${gridClasses.cell}[data-field="result"]`]: {
    'white-space': 'pre-wrap',
  },
}));

export default PatientJourneyGroup;
