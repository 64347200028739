import { appSettings } from '../AppSettings';
import { Sample } from './SampleData';
import { Dictionary } from '../util/TypeUtil';
import { BaseGet, BasePost } from './BaseData';
import { Transition } from './SampleTrackingTransitionData';
import { ExhaustionApproach } from './ReferenceData';
import { DeliveryCandidateType } from './InformaticsQualityCheckData';

export type ParentExhaustion = ExhaustionApproach;

export interface SampleTrackingCounts {
  researchProjectId: string;
  sampleGroup: string;
  sampleCounts: Dictionary<SampleTrackingCountRow>;
  patientCounts: Dictionary<SampleTrackingCountRow>;
}

export interface SampleTrackingCountRow {
  sampleGroup: string;
  transitionName: string;
  workLeftCount: number;
  passedCount: number;
  failedCount: number;
  remainingCount: number;
}

export interface LabAssignedSampleLabel {
  label: string;
  transitionSampleId: string;
  activeSince: Date;
}

export interface TransitionSample {
  transitionSampleId: string;
  transitionId: string;
  sampleId: string;
  transitionQualityCheckGroupId?: string;
  enteredAt: Date;
  exitedAt?: Date;
  createdAt?: Date;
  qualityCheckStatus: string;
  checkByFailedReason?: string;
  qualityCheckCreatedAt?: Date;
  sampleJourneyId: string;
}

export interface SampleTrackingTableData {
  sample: Sample;
  transitionSample: TransitionSample;
  antecedentSample?: Sample;
  parentTransitionSampleId?: string;
  sampleJourneyId: string;
  labAssignedSampleLabel?: LabAssignedSampleLabel;
}

export interface SampleTrackingSelectedTableData {
  sample: Sample;
  transitionSample?: TransitionSample;
  sampleJourneyId: string;
  labAssignedSampleLabel?: LabAssignedSampleLabel;
}

export interface SampleTrackingIdMapping {
  index: string;
  sampleIdentifier: string;
  labAssignedSampleId?: string;
  qualityCheckStatus: string;
  checkByFailedReason?: string;
  containerIdentifier?: string;
  containerSamplePosition?: string;
  containerType?: string;
  r1FastQLocation?: string;
  r2FastQLocation?: string;
  errorMessage?: IdMappingErrorMessageType[];
  warningMessage?: IdMappingErrorMessageType[];
  dynamicData?: Dictionary<string | undefined>;
}

export interface SampleTrackingCheckByValidation {
  sampleIdentifier: string;
  labAssignedSampleId?: string;
  qualityCheckStatus: string;
  containerIdentifier?: string;
  containerSamplePosition?: string;
  containerType?: string;
}

export interface SampleTrackingCheckByFastqValidation {
  sampleIdentifier: string;
  labAssignedSampleId?: string;
  r1FastQLocation?: string;
  r2FastQLocation?: string;
  transitionId: string;
}

export interface SampleTrackingCheckByFastqValidationResult {
  transitionId: string;
  sequencingLabId: string;
  sampleIdentifier: string;
  labAssignedSampleId?: string;
  r1FastQLocation?: string;
  r2FastQLocation?: string;
  r1FastQLocationRecommendation?: string;
  r2FastQLocationRecommendation?: string;
  existingSequenceRun?: SequenceRun;
}

export interface SampleTrackingCheckByFastqValidationResultPayload {
  validated: ReadonlyArray<SampleTrackingCheckByFastqValidationResult>;
  possibleFiles: string[];
}

export interface SequenceRun {
  sequenceId: string;
  bbid: string;
  opaqueIdentifier: string;
  batchId: string;
  batchIdentifier: string;
  sequenceGroupId: string;
  sequenceGroupName: string;
  sequenceGroupPurpose: string;
  tissueTypeId: number;
  libraryTypeId: string;
  tissueTypeName: string;
  capabilityIsAvailable: boolean;
  sequencingLabId: string;
  sequencingLabName: string;
  instrumentId: number;
  instrumentName: string;
  fastqR1Path: string;
  fastqR2Path?: string;
  versionNumber: string;
}

export type SampleTrackingCheckBy = {
  lookupId?: string;
  originalSampleId: string;
  sampleIdentifier: string;
  labAssignedSampleId?: string;
  qualityCheckStatus: string;
  checkByFailedReason?: string;
  containerIdentifier?: string;
  containerSamplePosition?: string;
  containerType?: string;
  r1FastQLocation?: string;
  r2FastQLocation?: string;
  dynamicData?: Dictionary<string | undefined>;
};

export interface SampleTrackingInformaticsCheckByDto {
  SampleTrackingCheckByDtos: SampleTrackingCheckBy[];
  SampleTrackingInformaticsReferenceCheckByDtos: SampleTrackingInformaticsReferenceCheckByDto[];
}

export interface SampleTrackingQuantificationCheckByDto {
  sampleTrackingCheckByDtos: ReadonlyArray<SampleTrackingCheckBy>;
  sampleTrackingQuantificationDetailsCheckByDtos: ReadonlyArray<SampleTrackingQuantificationDetailsCheckByDto>;
}

export interface SampleTrackingInformaticsReferenceCheckByDto {
  sampleId: string;
  informaticsPipelineResultId: string;
  deliveryCandidate: DeliveryCandidateType;
}

export interface SampleTrackingQuantificationDetailsCheckByDto {
  lookupId?: string;
  sampleId: string;
  yield?: number;
  concentration?: number;
  twoSixtyTwoEightyRatio?: number;
  volume?: number;
}

export interface SampleTrackingCheckByValidationResult {
  sampleIdentifier: string;
  labAssignedSampleId: string;
  mappedToSampleId?: string;
  mappedToSampleIdentifier?: SampleIdentifier;
  mappedToSampleCurrentTransition?: Transition;
  mappedFromSampleId?: string;
  mappedFromSampleIdentifier?: SampleIdentifier;
  mappedFromSampleCurrentTransition?: Transition;
  mappedFromSampleCurrentTransitionSample?: TransitionSample;
  mappedFromContainerIdentifier?: string;
  mappedFromContainerSamplePosition?: string;
  mappedFromContainerType?: string;
  containerIdentifierError?: string;
  containerSamplePositionError?: string;
  containerTypeError?: string;
  qualityCheckStatus: string;
}

export interface SampleIdentifier {
  labAssignedSampleId?: string;
  sampleBbid?: string;
  sourceKey?: string;
  sampleId: string;
}

export type IdMappingErrorMessageType =
  | 'missingContainerIdentifierAndPositionNotMissingType'
  | 'missingContainerIdentifierNotMissingPosition'
  | 'missingContainerPositionNotMissingIdentifier'
  | 'missingOriginalId'
  | 'missingNewLabAssignedId'
  | 'missingQualityCheckStatus'
  | 'invalidQualityCheckStatus'
  | 'tooManyParsedItemsDefault'
  | 'tooManyParsedItemsSequencing'
  | 'invalidCheckByFailedReason'
  | 'volumeIsNan'
  | 'yieldIsNan'
  | 'concentrationIsNan'
  | 'twoSixtyTwoEightyIsNan';

export type TransitionType =
  | 'Shipping'
  | 'Receiving'
  | 'Subsetting'
  | 'Extraction'
  | 'LibraryPrep'
  | 'Sequencing'
  | 'Informatics'
  | 'ReBiobanking'
  | 'ReadyForDelivery'
  | 'Delivered'
  | 'Generic'
  | 'GenericWithSampleOutput'
  | 'Quantification';

export type QualityCheckStatus = 'NotYetDecided' | 'Passed' | 'Failed';

export const QualityCheckStatuses: QualityCheckStatus[] = ['NotYetDecided', 'Passed', 'Failed'];
export const PersistableQualityCheckStatuses: QualityCheckStatus[] = ['Passed', 'Failed'];

export async function GetSampleTrackingCountsBySampleType(
  researchProjectId: string,
  accessToken: string
): Promise<ReadonlyArray<SampleTrackingCounts>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/BySampleGroup/ResearchProjectId/${researchProjectId}`,
    accessToken
  );
}

export async function GetSampleTrackingCountsBySampleTypeForResearchProjects(
  researchProjectIds: string[],
  accessToken: string
): Promise<ReadonlyArray<SampleTrackingCounts>> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/BySampleGroup`,
    researchProjectIds,
    accessToken
  );
}

export async function GetSampleTrackingSelectedSamples(
  researchProjectId: string,
  accessToken: string,
  decrypt: boolean = false,
  sampleGroupConstraint?: string
): Promise<ReadonlyArray<SampleTrackingSelectedTableData>> {
  const url = !sampleGroupConstraint
    ? `${appSettings.api.endpoint}/api/v2/SampleTracking/SelectedSamples/${researchProjectId}?decrypt=${decrypt}`
    : `${appSettings.api.endpoint}/api/v2/SampleTracking/SelectedSamples/${researchProjectId}?sampleGroupConstraint=${sampleGroupConstraint}&decrypt=${decrypt}`;

  return await BaseGet(url, accessToken);
}

export async function GetSampleTrackingTransitionSamples(
  researchProjectId: string,
  accessToken: string,
  configuredTransitionId: string,
  decrypt: boolean = false,
  sampleGroupConstraint?: string
): Promise<ReadonlyArray<SampleTrackingTableData>> {
  const url = !sampleGroupConstraint
    ? `${appSettings.api.endpoint}/api/v2/SampleTracking/${researchProjectId}/${configuredTransitionId}?decrypt=${decrypt}`
    : `${appSettings.api.endpoint}/api/v2/SampleTracking/${researchProjectId}/${configuredTransitionId}?sampleGroupConstraint=${sampleGroupConstraint}&decrypt=${decrypt}`;

  return await BaseGet(url, accessToken);
}

export async function CreateCheckBy(
  researchProjectId: string,
  configuredTransitionId: string,
  idMappings: ReadonlyArray<SampleTrackingCheckBy>,
  parentExhaustion: ParentExhaustion,
  accessToken?: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/CheckBy/ResearchProjectId/${researchProjectId}/Step/${configuredTransitionId}?parentExhaustion=${parentExhaustion}`,
    idMappings,
    accessToken
  );
}

export async function CreateInformaticsCheckBy(
  researchProjectId: string,
  configuredTransitionId: string,
  data: SampleTrackingInformaticsCheckByDto,
  accessToken?: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/CheckBy/Informatics/ResearchProjectId/${researchProjectId}/Step/${configuredTransitionId}`,
    data,
    accessToken
  );
}

export async function CreateQuantificationCheckBy(
  researchProjectId: string,
  configuredTransitionId: string,
  data: SampleTrackingQuantificationCheckByDto,
  accessToken?: string
): Promise<string> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/CheckBy/Quantification/ResearchProjectId/${researchProjectId}/Step/${configuredTransitionId}`,
    data,
    accessToken
  );
}

export async function CheckByValidation(
  researchProjectId: string,
  configuredTransitionId: string,
  idMappings: ReadonlyArray<SampleTrackingCheckByValidation>,
  accessToken?: string
): Promise<ReadonlyArray<SampleTrackingCheckByValidationResult>> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/CheckBy/Validate/ResearchProjectId/${researchProjectId}/Step/${configuredTransitionId}`,
    idMappings,
    accessToken
  );
}

export async function CheckByFastQValidation(
  idMappings: ReadonlyArray<SampleTrackingCheckByFastqValidation>,
  accessToken?: string
): Promise<ReadonlyArray<SampleTrackingCheckByFastqValidationResultPayload>> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleTracking/CheckBy/ValidateFastQs`,
    idMappings,
    accessToken
  );
}
