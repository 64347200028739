import { UserPermissions } from '../../auth/UseUserPermissions';
import { t } from 'i18next';

export interface MenuItem {
  link?: string;
  authPermissions?: Partial<UserPermissions>;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  heading: string;
  items: MenuItem[];
  authPermissions?: Partial<UserPermissions>;
}

export const menuItems: MenuItems[] = [
  {
    heading: t('dataServicesOperations'),
    items: [
      {
        name: t('diseaseAreas'),
        link: '/disease-areas',
        authPermissions: { hasGreenSideViewAccess: true },
      },
      {
        name: t('pathogens'),
        link: '/pathogens',
      },
      {
        name: t('researchProjects'),
        link: '/research-project',
        authPermissions: { hasGreenSideViewAccess: true, hasSampleTrackingViewAccess: true },
      },
      {
        name: t('dataProducts'),
        link: '/data-products',
        authPermissions: { hasGreenSideViewAccess: true },
      },
    ],
  },
  {
    heading: t('dashboards'),
    items: [
      {
        name: t('biobank'),
        link: '/biobank-metrics',
      },
      {
        name: t('lab'),
        link: '/lab-metrics',
      },
      {
        name: t('diseaseArea'),
        link: '/disease-area-metrics',
        authPermissions: { hasGreenSideViewAccess: true },
      },
    ],
  },
  {
    heading: t('superDuperFiesta'),
    authPermissions: { hasSuperDuperFiestaAccess: true },
    items: [
      {
        name: t('pipelineReview'),
        link: '/sdf-pipeline-review',
        authPermissions: { hasSuperDuperFiestaAccess: true },
      },
      {
        name: t('pipelines'),
        link: '/sdf-pipelines',
      },
      {
        name: t('pipelineRules'),
        link: '/sdf-pipeline-rules',
      },
      {
        name: t('migrations'),
        link: '/sdf-migrations',
        authPermissions: { hasSuperDuperFiestaAdminAccess: true },
      },
    ],
  },
  {
    heading: t('livingLabAdministration'),
    authPermissions: { hasAdminAccess: true },
    items: [
      {
        name: t('migrations'),
        link: '/migrations',
        authPermissions: { hasAdminAccess: true },
      },
      {
        name: t('generateBbids'),
        link: '/bbidgen',
        authPermissions: { hasAdminAccess: true },
      },
    ],
  },
  {
    heading: t('tokenization'),
    items: [
      {
        name: t('tokenization'),
        link: '/tokenization',
        authPermissions: { hasGreenSideViewAccess: true },
      },
    ],
  },
];
