import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useForm } from 'react-hook-form';
import useAuth from 'auth/UseAuth';
import PipelineRulesContext from '../../../../contexts/PipelineRulesContext';
import { LoadingState, ErrorManagement } from '../../../../components/LoadingStateUtil';
import { CreatePipelineRules, UpdatePipelineRule, DeletePipelineRule } from '../../../../data/SuperDuperFiestaData';
import {
  PipelineRule,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
  SuperDuperFiestaDataSource,
  PipelineRulesConfig,
} from '../../../../data/SuperDuperFiestaData';
import { buildRule, updateRows, removeFromList } from '../pipelineRulesUtils';
import { IRuleFormInput, defaultFormValues } from '../../../../contexts/PipelineRulesContext';

export const usePipelineRules = ({
  selectedEntity,
  selectedPipeline,
  selectedStage,
  allRows = [],
  selectedRow,
  setSelectedRow,
  clonedRules,
  setRuleCloneSelection,
  open,
  setOpen,
}: {
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
  allRows: PipelineRule[];
  selectedRow: PipelineRule | null;
  setSelectedRow: (row: PipelineRule | null) => void;
  clonedRules: PipelineRule[];
  setRuleCloneSelection: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  const { accessToken } = useAuth();
  const { getFilterData, pipelineFiltersState, setRefreshData } = useContext(PipelineRulesContext);

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [rows, setRows] = useState<PipelineRule[]>([]);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [newStepIds, setNewStepIds] = useState<string[]>([]);
  const [editedStepId, setEditedStepId] = useState<string | null>(null);
  const [selectedDataSources, setSelectedDataSources] = useState<SuperDuperFiestaDataSource[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    trigger,
    formState: { isDirty, isValid },
  } = useForm<IRuleFormInput>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  useEffect(() => {
    setIsNew(selectedRow === null && editedStepId === null);
  }, [selectedRow, editedStepId]);

  // Sets the form values for the selected row
  useEffect(() => {
    if (open && selectedRow !== null) {
      const editRule = {
        id: selectedRow.id,
        executionOrder: selectedRow.executionOrderDisplay,
        ruleName: selectedRow.name,
        description: selectedRow.description || '',
        queryCondition: selectedRow.queryCondition || '',
        queryJoin: selectedRow.queryJoin || '',
        queryGroupBy: selectedRow.queryGroupBy || '',
        needsReview: selectedRow.needsReview,
        active: selectedRow.active,
        pipeline: selectedPipeline,
        entity: selectedEntity,
        stage: selectedStage,
        stepUpdateValues: selectedRow.stepUpdateValues,
        stepType: pipelineFiltersState.stepTypes?.find(st => st.displayName === selectedRow.stepTypeName),
        dataClass: pipelineFiltersState.dataClasses?.find(dc => dc.name === selectedRow.dataClassName),
        issue: pipelineFiltersState.issues?.find(dc => dc.name === selectedRow.issueName),
      };

      const dataSource = pipelineFiltersState.dataSources?.find(ds => ds.displayName === selectedRow.dataSourceName);

      setSelectedDataSources(!dataSource ? [] : [dataSource]);
      reset(editRule);
    }
  }, [selectedRow, open, pipelineFiltersState, reset, selectedEntity, selectedPipeline, selectedStage]);

  // Sets the rows for the modal
  useEffect(() => {
    if (!open) return;

    const filteredRows = allRows
      .filter(r => r.hierarchy && r.hierarchy.length <= 1)
      .map((row, index) => ({
        ...row,
        id: index,
        executionOrder: index + 1,
        executionOrderDisplay: index + 1,
      }));

    if (clonedRules.length > 0) {
      const { stepTypes, dataClasses, issues, dataSources } = pipelineFiltersState;

      const builtRules = clonedRules.map(rule => {
        const stepType = stepTypes?.find(st => st.displayName === rule.stepTypeName);
        const dataClass = dataClasses?.find(dc => dc.name === rule.dataClassName);
        const issue = issues?.find(dc => dc.name === rule.issueName);
        const dataSource = dataSources?.find(ds => ds.displayName === rule.dataSourceName);

        return {
          ...rule,
          pipeline: selectedPipeline,
          entity: selectedEntity,
          stage: selectedStage,
          stepUpdateValues: rule.stepUpdateValues,
          stepTypeId: stepType?.stepTypeId,
          stepType,
          dataClassId: dataClass?.dataClassId,
          dataClass,
          issueId: issue?.issueId,
          issue,
          dataSourceId: dataSource?.dataSourceId,
          dataSource,
        };
      });

      const orderedRows = updateRows(builtRules, null, filteredRows);
      const newRowStepIds = builtRules.map(r => r.stepId);

      setNewStepIds(prev => [...prev, ...newRowStepIds].filter(r => r !== null));
      setRows(orderedRows);
    } else {
      setRows(filteredRows);
    }
  }, [allRows, clonedRules, open, pipelineFiltersState, selectedEntity, selectedPipeline, selectedStage]);

  // Checks for duplicate rules based on rule name, active, and data source
  const hasDuplicateRules = useMemo(() => {
    const ruleMap = new Map<string, Set<string>>();

    for (const rule of rows) {
      const key = `${rule.name}|${rule.dataSourceId}|${rule.active}`;
      if (!ruleMap.has(key)) {
        ruleMap.set(key, new Set());
      }
      ruleMap.get(key)!.add(rule.stepId);
    }

    return Array.from(ruleMap.values()).some(stepIds => stepIds.size > 1);
  }, [rows]);

  // Resets the form and clears selected data
  const clearForm = useCallback(() => {
    reset(defaultFormValues);
    setSelectedDataSources([]);
    setSelectedRow(null);
    setValue('pipeline', selectedPipeline);
    setValue('entity', selectedEntity);
    setValue('stage', selectedStage);
  }, [reset, setSelectedRow, setValue, selectedPipeline, selectedEntity, selectedStage]);

  // Opens the modal and initializes form data
  const handleOpen = useCallback(async () => {
    setOpen(true);
    getFilterData();
    setValue('pipeline', selectedPipeline);
    setValue('entity', selectedEntity);
    setValue('stage', selectedStage);
  }, [getFilterData, setValue, selectedPipeline, selectedEntity, selectedStage, setOpen]);

  // Closes the modal and resets all state
  const handleClose = useCallback(() => {
    setOpen(false);
    clearForm();
    setRows([]);
    setNewStepIds([]);
    setEditedStepId(null);
    setRuleCloneSelection([]);
    setLoadingState({ status: 'NotStarted' });
  }, [setOpen, clearForm, setRows, setNewStepIds, setEditedStepId, setRuleCloneSelection, setLoadingState]);

  // Handles adding a new rule or updating an existing one
  const handleAddOrUpdateRule = useCallback(
    (data: IRuleFormInput) => {
      const firstDataSource = selectedDataSources[0];
      if (isNew) {
        let newRows: PipelineRule[] = [];
        const newRowsParams = buildRule(data, null, rows, firstDataSource);

        if (newRowsParams === undefined) {
          return;
        }

        if (selectedDataSources.length > 0 && selectedDataSources.length < pipelineFiltersState.dataSources?.length) {
          selectedDataSources.forEach((ds, idx) => {
            newRows.push({
              ...newRowsParams,
              id: newRowsParams.id + idx,
              executionOrder: newRowsParams.executionOrder + idx,
              executionOrderDisplay: newRowsParams.executionOrder + idx,
              dataSourceName: ds.displayName,
              dataSourceId: ds.dataSourceId,
            });
          });
        } else {
          newRows.push({
            ...newRowsParams,
            dataSourceName: null,
            dataSourceId: null,
          });
        }

        const orderedRows = updateRows(newRows, null, rows);
        const newRowStepIds = newRows.map(r => r.stepId);

        setNewStepIds(prev => [...prev, ...newRowStepIds].filter(r => r !== null));
        setRows(orderedRows);
      } else {
        if (selectedRow === null || selectedRow.id === undefined) {
          return;
        }
        const updatedRowsParams = buildRule(data, selectedRow, rows, firstDataSource);

        if (updatedRowsParams === undefined) {
          return;
        }

        const updatedRule = { ...selectedRow, ...updatedRowsParams };
        const currentIndex = rows.findIndex(r => r.id === selectedRow.id);
        const orderedRows = updateRows([updatedRule], currentIndex, rows);

        if (newStepIds.includes(updatedRowsParams.stepId)) {
          setSelectedRow(null);
        } else {
          setEditedStepId(updatedRowsParams.stepId);
        }
        setRows(orderedRows);
      }
      clearForm();
    },
    [
      isNew,
      selectedDataSources,
      pipelineFiltersState.dataSources,
      newStepIds,
      rows,
      selectedRow,
      clearForm,
      setNewStepIds,
      setRows,
      setSelectedRow,
      setEditedStepId,
    ]
  );

  // Saves new rules to the backend
  const handleSave = useCallback(async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (selectedPipeline && selectedStage && newStepIds.length > 0) {
        const newRules = rows.filter(r => newStepIds.includes(r.stepId));
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
        };
        await CreatePipelineRules(newRules, queryParams, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  }, [selectedPipeline, selectedStage, newStepIds, rows, accessToken, handleClose, setRefreshData]);

  // Updates an existing rule in the backend
  const handleUpdate = useCallback(async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const editedRule = rows.find(r => r.stepId === editedStepId);
      if (selectedPipeline && selectedStage && editedStepId && editedRule) {
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
        };
        await UpdatePipelineRule(editedRule.stepId, editedRule, queryParams, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  }, [selectedPipeline, selectedStage, editedStepId, rows, accessToken, handleClose, setRefreshData]);

  // Deletes a rule from the backend
  const handleDelete = useCallback(async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (selectedRow !== null && selectedRow.stepId !== null) {
        await DeletePipelineRule(selectedRow.stepId, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  }, [selectedRow, accessToken, handleClose, setRefreshData]);

  // Removes a row from the local state
  const removeRow = useCallback(
    (stepId: string) => {
      const newRows = removeFromList(stepId, rows);
      if (newRows) {
        setRows(newRows);
      }

      setNewStepIds(prev => prev.filter(r => r !== stepId));
    },
    [rows]
  );

  // Sets a row as selected for editing
  const editRow = useCallback(
    (stepId: string) => {
      const selectedRule = rows.find(r => r.stepId === stepId);
      if (selectedRule) {
        setSelectedRow(selectedRule);
      }
    },
    [rows, setSelectedRow]
  );

  // Handles form submission
  const onSubmit = useCallback(
    (data: IRuleFormInput) => {
      handleAddOrUpdateRule(data);
    },
    [handleAddOrUpdateRule]
  );

  return {
    loadingState,
    rows,
    setRows,
    open,
    setOpen,
    isNew,
    newStepIds,
    editedStepId,
    selectedDataSources,
    setSelectedDataSources,
    hasDuplicateRules,
    handleOpen,
    handleClose,
    handleAddOrUpdateRule,
    handleSave,
    handleUpdate,
    handleDelete,
    removeRow,
    editRow,
    control,
    trigger,
    watch,
    setValue,
    isDirty,
    isValid,
    handleSubmit,
    onSubmit,
    clearForm,
    pipelineFiltersState,
    formState: { isDirty, isValid },
  };
};
