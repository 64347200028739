import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import {
  CheckByFastQValidation,
  CheckByValidation,
  CreateCheckBy,
  IdMappingErrorMessageType,
  ParentExhaustion,
  PersistableQualityCheckStatuses,
  QualityCheckStatus,
  SampleTrackingCheckBy,
  SampleTrackingCheckByFastqValidationResultPayload,
  SampleTrackingCheckByValidationResult,
  SampleTrackingIdMapping,
  TransitionType,
} from '../../data/SampleTrackingData';
import { SampleTrackingIdMappingGrid } from './SampleTrackingIdMappingGrid';
import { recordQc } from '../../util/Constants';
import { find, findIndex, keyBy, map, values } from 'lodash';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { CreateSupplementalFilesModal } from './supplementalFiles/CreateSupplementalFilesModal';
import { CreateSupplementalFilesForQualityCheckGroupId, SupplementalFile } from '../../data/SupplementalFileData';
import { ErrorManagement, LoadingProps, LoadingState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import UseMemoTranslation from '../../hooks/UseMemoTranslation';
import useTransitionEnums from '../../components/hooks/UseTransitionEnums';
import { FastQData, SampleTrackingIdMappingConfirmationGrid } from './SampleTrackingIdMappingConfirmationGrid';
import { CheckByFailedReason, SampleIdResolverApproach, TransitionEnum } from '../../data/ReferenceData';
import { DialogTypography } from '../../components/DialogTypography';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { BuildCheckByConfiguration, CheckByConfiguration } from './CheckByConfiguration';
import { Dictionary, UseState, UseStateSetter } from '../../util/TypeUtil';
import { FlexBox } from '../../components/FlexBox';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { GridExportButton } from '../../components/GridExportButton';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { CheckByAvailableSequenceFilesGrid } from './CheckByAvailableSequenceFilesGrid';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { FlexTableBox } from '../../components/FlexTableBox';
import useCheckByFailedReasons from '../../components/hooks/UseCheckByFailedReasons';
import { useConfiguredTransition } from '../../components/hooks/UseConfiguredTransitions';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';
import { GridTextParsingState } from 'components/GridTextParsing';

type OnCloseFunction = (hasChanges: boolean) => void;
type WidthSize = 'sm' | 'md' | 'lg' | 'xl';

export interface CreateSampleTrackingIdMappingModalProps {
  researchProjectId: string;
  transitionType: TransitionType;
  configuredTransitionId: string;
  onClose?: (hasChanges: boolean) => void;
}

export const CheckByModal = ({
  researchProjectId,
  transitionType,
  configuredTransitionId,
  onClose = () => {},
}: CreateSampleTrackingIdMappingModalProps) => {
  const { t } = UseMemoTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const transitionEnums = useTransitionEnums();

  const [sampleIdResolverApproach, setSampleIdResolverApproach] = useState<SampleIdResolverApproach>('normal');
  const [transitionEnum, setTransitionEnum] = useState<TransitionEnum>();

  const configuredTransition = useConfiguredTransition(researchProjectId, configuredTransitionId, setLoadingState);
  const [config, setConfig] = useState<CheckByConfiguration>();

  useEffect(() => {
    if (!transitionEnums || !transitionType || !configuredTransition) {
      return;
    }

    const transition = find(transitionEnums, i => i.name === transitionType);
    setTransitionEnum(transition);
    setSampleIdResolverApproach(transition?.sampleIdResolverApproach ?? 'normal');
    setConfig(BuildCheckByConfiguration(configuredTransition, t as any));
  }, [transitionEnums, transitionType, modalOpen, configuredTransition, t]);

  function handleCLose(hasChanges: boolean) {
    onClose(hasChanges);
    setLoadingState({ status: 'NotStarted' });
    setModalOpen(false);
  }

  function getModal() {
    if (!transitionEnum || !configuredTransition || !config) {
      return <></>;
    }

    switch (sampleIdResolverApproach) {
      case 'normal':
        return (
          <NormalResolverCheckByModal
            {...{ researchProjectId, configuredTransition }}
            onClose={handleCLose}
            transitionEnum={transitionEnum}
            config={config}
            loadingProps={[loadingState, setLoadingState]}
          />
        );

      case 'historic':
        return (
          <HistoricResolverCheckByModal
            {...{ researchProjectId, configuredTransition }}
            onClose={handleCLose}
            transitionEnum={transitionEnum}
            config={config}
            loadingProps={[loadingState, setLoadingState]}
          />
        );
    }
  }

  return (
    <>
      <DialogOpenButton title={t(recordQc)} onClick={() => setModalOpen(true)} />
      {modalOpen && getModal()}
    </>
  );
};

const NormalResolverCheckByModal = ({
  config,
  transitionEnum,
  onClose,
  researchProjectId,
  configuredTransition,
  loadingProps,
}: {
  config: CheckByConfiguration;
  transitionEnum: TransitionEnum;
  onClose: OnCloseFunction;
  researchProjectId: string;
  configuredTransition: ConfiguredTransition;
  loadingProps: LoadingProps;
}) => {
  const [state, setState] = useState<GridTextParsingState>('TextGrid');
  const [loadingState, setLoadingState] = loadingProps;

  const { accessToken } = useAuth();

  const [parentExhaustion, setParentExhaustion] = useState<ParentExhaustion>('none');
  const [supportsSampleExhaustion, setSupportsSampleExhaustion] = useState<boolean>(true);
  const checkByFailedReasons = useCheckByFailedReasons(transitionEnum.transitionEnumId);

  const [stringData, setStringData] = useState<string>('');
  const [parsedData, setParsedData] = useState<SampleTrackingIdMapping[]>([]);
  const [supplementalFiles, setSupplementalFiles] = useState<SupplementalFile[]>([]);

  useEffect(() => {
    setParentExhaustion(configuredTransition?.exhaustionApproach ?? 'none');
    setSupportsSampleExhaustion(configuredTransition?.enableSampleExhaustion);
  }, [configuredTransition]);

  function handleClose(hasChanges: boolean) {
    onClose(hasChanges);
    setState('TextGrid');
    setParsedData([]);
    setSupplementalFiles([]);
    setLoadingState({ status: 'NotStarted' });
  }

  function createQualityCheckWrapped(dataToSave: SampleTrackingIdMapping[]) {
    ErrorManagement('Loading', setLoadingState, () => createQualityCheck(dataToSave));
  }

  async function createQualityCheck(dataToSave: SampleTrackingIdMapping[]) {
    if (!accessToken) {
      return;
    }

    const checkByValidationResults = await CheckByValidation(
      researchProjectId,
      configuredTransition.configuredTransitionId,
      dataToSave.map(i => {
        return {
          sampleIdentifier: i.sampleIdentifier,
          labAssignedSampleId: i.labAssignedSampleId,
          qualityCheckStatus: i.qualityCheckStatus,
          containerIdentifier: i.containerIdentifier,
          containerSamplePosition: i.containerSamplePosition,
          containerType: i.containerType,
        };
      }),
      accessToken
    );

    const invalidData = checkByValidationResults.filter(
      i => !i.mappedToSampleId || i.containerIdentifierError || i.containerSamplePositionError || i.containerTypeError
    );

    if (invalidData.length > 0) {
      const sampleIdentifierErrors = invalidData
        .filter(i => !i.mappedToSampleId)
        .map(i => `'${i.sampleIdentifier}' did not resolve to a sample.`);

      // No need to include container errors for invalid sample identifier mappings as the
      // container details won't matter if the sample does not map to anything.
      const containerErrors = invalidData
        .filter(
          i =>
            i.mappedToSampleId && (i.containerIdentifierError || i.containerSamplePositionError || i.containerTypeError)
        )
        .map(
          i =>
            `Sample '${i.sampleIdentifier}' had container input error(s):` +
            `\n${i.containerIdentifierError ? `${i.containerIdentifierError}` : ''}` +
            `\n${i.containerIdentifierError ? `${i.containerSamplePositionError}` : ''}` +
            `\n${i.containerIdentifierError ? `${i.containerTypeError}` : ''}`
        );

      const errorMessage = sampleIdentifierErrors.concat(containerErrors).join('\n');

      setLoadingState({
        status: 'Error',
        errorMessage: errorMessage,
      });

      return;
    }

    const data = checkByValidationResults.map(i => {
      const original = find(
        dataToSave,
        p => p.sampleIdentifier === i.sampleIdentifier && p.labAssignedSampleId === i.labAssignedSampleId
      );

      const toReturn: SampleTrackingCheckBy = {
        ...original,
        originalSampleId: i.mappedToSampleId ?? '',
        sampleIdentifier: i.sampleIdentifier,
        labAssignedSampleId: i.labAssignedSampleId,
        qualityCheckStatus: i.qualityCheckStatus,
        checkByFailedReason: original?.checkByFailedReason,
        containerIdentifier: original?.containerIdentifier,
        containerSamplePosition: original?.containerSamplePosition,
        containerType: original?.containerType,
        r1FastQLocation: original?.r1FastQLocation,
        r2FastQLocation: original?.r2FastQLocation,
      };

      return toReturn;
    });

    let qualityCheckGroupId: string;

    if (config.customHandle !== undefined) {
      qualityCheckGroupId = await config.customHandle(
        researchProjectId,
        configuredTransition.configuredTransitionId,
        data,
        parentExhaustion,
        accessToken
      );
    } else {
      qualityCheckGroupId = await CreateCheckBy(
        researchProjectId,
        configuredTransition.configuredTransitionId,
        data,
        parentExhaustion,
        accessToken
      );
    }

    for (const file of supplementalFiles) {
      await CreateSupplementalFilesForQualityCheckGroupId(qualityCheckGroupId, file, accessToken);
    }

    await onClose(true);
  }

  function getModal() {
    switch (state) {
      case 'TextGrid':
        return (
          <TextGridModal
            {...{ config, transitionEnum, checkByFailedReasons, configuredTransition }}
            onClose={handleClose}
            loadingProps={[loadingState, setLoadingState]}
            stringDataProps={[stringData, setStringData]}
            setParsedData={data => {
              setParsedData(data);
              setState('ParsedGrid');
            }}
          />
        );

      case 'ParsedGrid':
        return (
          <ParsedGridModal
            {...{ config, transitionEnum, checkByFailedReasons, configuredTransition }}
            onClose={handleClose}
            loadingProps={[loadingState, setLoadingState]}
            supplementalFileProps={[supplementalFiles, setSupplementalFiles]}
            parentExhaustionProps={[parentExhaustion, setParentExhaustion]}
            supportsSampleExhaustion={supportsSampleExhaustion}
            data={parsedData}
            onSubmit={createQualityCheckWrapped}
            handleBackToOriginal={() => {
              setState('TextGrid');
              setParsedData([]);
              setLoadingState({ status: 'NotStarted' });
            }}
          />
        );
    }
  }

  return <>{<BaseModal maxWidth={'xl'}>{getModal()}</BaseModal>}</>;
};

const HistoricResolverCheckByModal = ({
  config,
  transitionEnum,
  onClose,
  researchProjectId,
  configuredTransition,
  loadingProps,
}: {
  config: CheckByConfiguration;
  transitionEnum: TransitionEnum;
  onClose: OnCloseFunction;
  researchProjectId: string;
  configuredTransition: ConfiguredTransition;
  loadingProps: LoadingProps;
}) => {
  const { accessToken } = useAuth();

  const [state, setState] = useState<GridTextParsingState>('TextGrid');
  const [loadingState, setLoadingState] = loadingProps;

  const [parentExhaustion, setParentExhaustion] = useState<ParentExhaustion>('none');
  const [supportsSampleExhaustion, setSupportsSampleExhaustion] = useState<boolean>(true);
  const checkByFailedReasons = useCheckByFailedReasons(transitionEnum.transitionEnumId);

  const [stringData, setStringData] = useState<string>('');
  const [parsedData, setParsedData] = useState<SampleTrackingIdMapping[]>([]);
  const [validationResult, setValidationResult] = useState<ReadonlyArray<SampleTrackingCheckByValidationResult>>([]);
  const [fastQValidationResult, setFastQValidationResult] = useState<
    Dictionary<SampleTrackingCheckByFastqValidationResultPayload>
  >({});
  const [supplementalFiles, setSupplementalFiles] = useState<SupplementalFile[]>([]);

  useEffect(() => {
    setParentExhaustion(configuredTransition?.exhaustionApproach ?? 'none');
    setSupportsSampleExhaustion(configuredTransition?.enableSampleExhaustion ?? false);
  }, [configuredTransition]);

  function handleCLose(hasChanges: boolean) {
    onClose(hasChanges);
    setState('TextGrid');
    setParsedData([]);
    setSupplementalFiles([]);
    setLoadingState({ status: 'NotStarted' });
  }

  function getCheckByValidationWrapped(
    dataToSave: SampleTrackingIdMapping[],
    setHasValidatedDataErrors: UseStateSetter<boolean>
  ) {
    ErrorManagement('Loading', setLoadingState, () => getCheckByValidation(dataToSave, setHasValidatedDataErrors));
  }

  async function getCheckByValidation(
    dataToSave: SampleTrackingIdMapping[],
    setHasValidatedDataErrors: UseStateSetter<boolean>
  ) {
    const checkByValidationResults = await CheckByValidation(
      researchProjectId,
      configuredTransition.configuredTransitionId,
      dataToSave.map(i => {
        return {
          sampleIdentifier: i.sampleIdentifier,
          labAssignedSampleId: i.labAssignedSampleId,
          qualityCheckStatus: i.qualityCheckStatus,
          containerIdentifier: i.containerIdentifier,
          containerSamplePosition: i.containerSamplePosition,
          containerType: i.containerType,
        };
      }),
      accessToken
    );

    const validationDataResult = map([...checkByValidationResults], i => {
      return { ...i, errorMessage: !i.mappedToSampleId ? 'Did not resolve to a sample.' : '' };
    });

    const hasErrors = validationDataResult.some(
      r =>
        (r.errorMessage?.length ?? 0) > 0 ||
        r.containerIdentifierError ||
        r.containerSamplePositionError ||
        r.containerTypeError
    );

    setHasValidatedDataErrors(hasErrors);
    setValidationResult(validationDataResult);

    const fastQsToValidate = checkByValidationResults
      .map(i => {
        const original = find(
          dataToSave,
          p => p.sampleIdentifier === i.sampleIdentifier && p.labAssignedSampleId === i.labAssignedSampleId
        );

        return {
          sampleIdentifier: i.sampleIdentifier,
          labAssignedSampleId: i.labAssignedSampleId,
          transitionId: i.mappedToSampleCurrentTransition?.transitionId ?? '',
          r1FastQLocation: original?.r1FastQLocation ?? '',
          r2FastQLocation: original?.r2FastQLocation ?? '',
        };
      })
      .filter(i => i.transitionId);

    const fastQResult =
      fastQsToValidate.length !== 0 ? await CheckByFastQValidation(fastQsToValidate, accessToken) : [];
    setFastQValidationResult(keyBy(fastQResult, i => i.validated[0].transitionId));

    setLoadingState({ status: 'Complete' });
    setState('Validation');
  }

  function createQualityCheckWrapped(dataToSave: SampleTrackingCheckBy[]) {
    ErrorManagement('Loading', setLoadingState, () => createQualityCheck(dataToSave));
  }

  async function createQualityCheck(dataToSave: SampleTrackingCheckBy[]) {
    const qualityCheckGroupId = await CreateCheckBy(
      researchProjectId,
      configuredTransition.configuredTransitionId,
      dataToSave,
      parentExhaustion,
      accessToken
    );

    for (const file of supplementalFiles) {
      await CreateSupplementalFilesForQualityCheckGroupId(qualityCheckGroupId, file, accessToken);
    }

    await onClose(true);
  }

  function getModal() {
    switch (state) {
      case 'TextGrid':
        return (
          <TextGridModal
            {...{ config, transitionEnum, checkByFailedReasons, configuredTransition }}
            onClose={handleCLose}
            loadingProps={[loadingState, setLoadingState]}
            stringDataProps={[stringData, setStringData]}
            setParsedData={async data => {
              setParsedData(data);

              const hasErrors = data.some(r => (r.errorMessage?.length ?? 0) > 0);

              if (!hasErrors) {
                getCheckByValidationWrapped(data, () => {});
              } else {
                setState('ParsedGrid');
              }
            }}
          />
        );

      case 'ParsedGrid':
        return (
          <ParsedGridModal
            {...{ config, transitionEnum, checkByFailedReasons, configuredTransition }}
            onClose={handleCLose}
            loadingProps={[loadingState, setLoadingState]}
            supplementalFileProps={[supplementalFiles, setSupplementalFiles]}
            parentExhaustionProps={[parentExhaustion, setParentExhaustion]}
            supportsSampleExhaustion={supportsSampleExhaustion}
            data={parsedData}
            onSubmit={getCheckByValidationWrapped}
            handleBackToOriginal={() => {
              setState('TextGrid');
              setParsedData([]);
            }}
            submitBtnTextKey={'validateMappings'}
          />
        );

      case 'Validation':
        return (
          <ValidationGridModal
            {...{ transitionEnum, configuredTransition }}
            onClose={handleCLose}
            loadingProps={[loadingState, setLoadingState]}
            supplementalFileProps={[supplementalFiles, setSupplementalFiles]}
            parentExhaustionProps={[parentExhaustion, setParentExhaustion]}
            supportsSampleExhaustion={supportsSampleExhaustion}
            data={validationResult}
            fastQValidationData={fastQValidationResult}
            onSubmit={createQualityCheckWrapped}
            handleBackToOriginal={() => {
              setState('TextGrid');
              setParsedData([]);
              setLoadingState({ status: 'NotStarted' });
            }}
          />
        );
    }
  }

  return <>{<BaseModal maxWidth={'xl'}>{getModal()}</BaseModal>}</>;
};

const TextGridModal = ({
  config,
  transitionEnum,
  onClose,
  setParsedData,
  loadingProps,
  stringDataProps,
  checkByFailedReasons,
  configuredTransition,
}: {
  config: CheckByConfiguration;
  transitionEnum: TransitionEnum;
  onClose: OnCloseFunction;
  setParsedData: (data: SampleTrackingIdMapping[]) => void;
  loadingProps: LoadingProps;
  stringDataProps: UseState<string>;
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>;
  configuredTransition: ConfiguredTransition;
}) => {
  const { t } = UseMemoTranslation();
  const isSequencing = transitionEnum.name === 'Sequencing';

  const [loadingState] = loadingProps;
  const [stringData, setStringData] = stringDataProps;

  function processData() {
    let index = 1;
    let maxParsedValues = config.maxParsedValues;

    const data: SampleTrackingIdMapping[] = [];

    stringData.split('\n').forEach(rowString => {
      if (!rowString || rowString.length === 0) {
        return;
      }

      const row = rowString.split(/[\t,]/);
      let warningMessages: IdMappingErrorMessageType[] = [];

      if (row.length > maxParsedValues) {
        warningMessages.push(!isSequencing ? 'tooManyParsedItemsDefault' : 'tooManyParsedItemsSequencing');
      }

      const parsedRow = config.rowBuilder(row, index++, config);

      parsedRow.warningMessage = warningMessages;
      parsedRow.errorMessage = getRowErrorMessages(parsedRow, config, checkByFailedReasons);

      data.push(parsedRow);
    });

    setParsedData([...data]);
  }

  return (
    <>
      <BaseHeader {...{ transitionEnum, onClose, configuredTransition }} />
      <DialogContent>
        <Box sx={{ marginTop: 1 }}>
          <TextField
            multiline
            fullWidth
            label='Mapping'
            type='text'
            onChange={event => setStringData(event.target.value)}
            helperText={t(config.inputTextKey as any)}
            autoFocus
            rows={15}
            variant='outlined'
            value={stringData}
            spellCheck={false}
          />
        </Box>
      </DialogContent>
      <BaseActions {...{ onClose, loadingState }}>
        <PrimaryButton onClick={processData} disabled={stringData.length === 0 || loadingState.status === 'Loading'}>
          {t('parse')}
        </PrimaryButton>
      </BaseActions>
    </>
  );
};

const ParsedGridModal = ({
  config,
  transitionEnum,
  onClose,
  data,
  onSubmit,
  loadingProps,
  supplementalFileProps,
  handleBackToOriginal,
  parentExhaustionProps,
  supportsSampleExhaustion,
  submitBtnTextKey = 'submit',
  checkByFailedReasons,
  configuredTransition,
}: {
  config: CheckByConfiguration;
  transitionEnum: TransitionEnum;
  onClose: OnCloseFunction;
  data: SampleTrackingIdMapping[];
  onSubmit: (data: SampleTrackingIdMapping[], setHasValidatedDataErrors: UseStateSetter<boolean>) => void;
  loadingProps: LoadingProps;
  supplementalFileProps: UseState<SupplementalFile[]>;
  handleBackToOriginal: () => void;
  parentExhaustionProps: UseState<ParentExhaustion>;
  supportsSampleExhaustion: boolean;
  submitBtnTextKey?: 'submit' | 'validateMappings';
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>;
  configuredTransition: ConfiguredTransition;
}) => {
  const { t } = UseMemoTranslation();

  const [loadingState] = loadingProps;
  const [dataToSave, setDataToSave] = useState<SampleTrackingIdMapping[]>(data);

  const [hasParsedDataValidationErrors, setHasParsedDataValidationErrors] = useState<boolean>(false);
  const [hasValidatedDataErrors, setHasValidatedDataErrors] = useState<boolean>(false);

  useEffect(() => {
    const hasErrors = dataToSave.some(r => (r.errorMessage?.length ?? 0) > 0);
    setHasParsedDataValidationErrors(hasErrors);
  }, [dataToSave]);

  return (
    <>
      <BaseHeader {...{ transitionEnum, onClose, configuredTransition }}>
        <DialogTypography variant='subtitle1'>{t('sampleIdMappingGridInfo')}</DialogTypography>
      </BaseHeader>
      <DialogContent>
        <Box sx={{ marginTop: 1, height: '50vh' }}>
          <SampleTrackingIdMappingGrid
            data={[...dataToSave]}
            onRowUpdate={(rowIndex, newRow) => {
              const index = findIndex(dataToSave, row => row.index === rowIndex);

              if (index !== -1) {
                const row: SampleTrackingIdMapping = {
                  ...newRow,
                  ...dataToSave[index],
                  sampleIdentifier: newRow.sampleIdentifier,
                  labAssignedSampleId: newRow.labAssignedSampleId,
                  qualityCheckStatus: newRow.qualityCheckStatus,
                  checkByFailedReason: newRow.checkByFailedReason,
                  containerIdentifier: newRow.containerIdentifier,
                  containerSamplePosition: newRow.containerSamplePosition,
                  containerType: newRow.containerType,
                  r1FastQLocation: newRow.r1FastQLocation,
                  r2FastQLocation: newRow.r2FastQLocation,
                  dynamicData: newRow.dynamicData,
                };

                row.errorMessage = [...getRowErrorMessages(row, config, checkByFailedReasons)];
                dataToSave[index] = row;
              }

              setDataToSave([...dataToSave]);
            }}
            {...{ config, transitionEnum, checkByFailedReasons }}
          />
        </Box>
        {supportsSampleExhaustion && <ExhaustionActions {...{ parentExhaustionProps }} />}
      </DialogContent>
      <BaseActions
        {...{ onClose, loadingState }}
        leftChildren={<SupplementalFilesActions {...{ supplementalFileProps }} />}
      >
        <PrimaryButton onClick={handleBackToOriginal} disabled={loadingState.status === 'Loading'}>
          {t('backToOriginal')}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => onSubmit(dataToSave, setHasValidatedDataErrors)}
          disabled={
            hasParsedDataValidationErrors ||
            hasValidatedDataErrors ||
            dataToSave.length === 0 ||
            loadingState.status === 'Loading'
          }
        >
          {t(submitBtnTextKey)}
        </PrimaryButton>
      </BaseActions>
    </>
  );
};

const ValidationGridModal = ({
  transitionEnum,
  onClose,
  data,
  onSubmit,
  loadingProps,
  supplementalFileProps,
  handleBackToOriginal,
  parentExhaustionProps,
  supportsSampleExhaustion,
  fastQValidationData,
  configuredTransition,
}: {
  transitionEnum: TransitionEnum;
  onClose: OnCloseFunction;
  data: ReadonlyArray<SampleTrackingCheckByValidationResult>;
  onSubmit: (data: SampleTrackingCheckBy[], setHasValidatedDataErrors: UseStateSetter<boolean>) => void;
  loadingProps: LoadingProps;
  supplementalFileProps: UseState<SupplementalFile[]>;
  handleBackToOriginal: () => void;
  parentExhaustionProps: UseState<ParentExhaustion>;
  supportsSampleExhaustion: boolean;
  fastQValidationData: Dictionary<SampleTrackingCheckByFastqValidationResultPayload>;
  configuredTransition: ConfiguredTransition;
}) => {
  const { t } = UseMemoTranslation();
  const checkByConfirmationGridApiRef = useGridApiRef();

  const [loadingState] = loadingProps;
  const [fastQDataToSave, setFastQDataToSave] = useState<FastQData[]>([]);
  const [validity, setValidity] = useState<Dictionary<boolean>>({});

  const [hasValidatedDataErrors, setHasValidatedDataErrors] = useState<boolean>(false);

  useEffect(() => {
    const tmp = data.map(i => {
      const original = find(
        values(fastQValidationData).flatMap(i => i.validated),
        j => j.labAssignedSampleId === i.labAssignedSampleId
      );

      return {
        r1FastQLocation: original?.existingSequenceRun?.fastqR1Path ?? original?.r1FastQLocation ?? '',
        r2FastQLocation: original?.existingSequenceRun?.fastqR2Path ?? original?.r2FastQLocation ?? '',
        outputId: i.labAssignedSampleId ?? '',
      };
    });

    setFastQDataToSave(tmp);
  }, [data, fastQValidationData]);

  const onFastQUpdate = useCallback(
    (outputId: string, r1FastQ: string, r2FastQ?: string) => {
      const index = findIndex(fastQDataToSave, row => row.outputId === outputId);

      if (index !== -1) {
        fastQDataToSave[index] = {
          ...fastQDataToSave[index],
          r1FastQLocation: r1FastQ,
          r2FastQLocation: r2FastQ,
        };
      }

      setFastQDataToSave([...fastQDataToSave]);
    },
    [fastQDataToSave]
  );

  return (
    <>
      <BaseHeader {...{ transitionEnum, onClose, configuredTransition }} sx={{ pb: 0 }}>
        <DialogTypography variant='subtitle1'>{t('sampleIdMappingGridInfo')}</DialogTypography>
      </BaseHeader>
      <DialogContent>
        <FlexTableBox sx={{ marginTop: 0, height: '50vh' }}>
          <FlexBox flexDirection={'row-reverse'} sx={{ mt: 0 }}>
            <SequenceFilesModal data={values(fastQValidationData)} configuredTransition={configuredTransition} />
            <GridExportButton apiRef={checkByConfirmationGridApiRef} fileName={t('checkByFileName')} />
          </FlexBox>
          <SampleTrackingIdMappingConfirmationGrid
            apiRef={checkByConfirmationGridApiRef}
            transitionEnum={transitionEnum}
            data={data}
            fastQValidationData={fastQValidationData}
            fastQData={fastQDataToSave}
            onFastQUpdate={onFastQUpdate}
            setValidity={(outputId, isValid) => {
              setValidity(prevState => ({ ...prevState, [outputId]: isValid }));
            }}
          />
        </FlexTableBox>
        {supportsSampleExhaustion && <ExhaustionActions {...{ parentExhaustionProps }} />}
      </DialogContent>
      <BaseActions
        {...{ onClose, loadingState }}
        leftChildren={<SupplementalFilesActions {...{ supplementalFileProps }} />}
      >
        <PrimaryButton onClick={handleBackToOriginal} disabled={loadingState.status === 'Loading'}>
          {t('backToOriginal')}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            const outGoing = data.map(i => {
              const original = find(fastQDataToSave, p => p.outputId === i.labAssignedSampleId);

              const toReturn: SampleTrackingCheckBy = {
                sampleIdentifier: i.sampleIdentifier,
                labAssignedSampleId: i.labAssignedSampleId,
                originalSampleId: i.mappedToSampleId ?? '',
                qualityCheckStatus: i.qualityCheckStatus,
                containerIdentifier: i.mappedFromContainerIdentifier,
                containerSamplePosition: i.mappedFromContainerSamplePosition,
                containerType: i.mappedFromContainerType,
                r1FastQLocation: original?.r1FastQLocation,
                r2FastQLocation: original?.r2FastQLocation,
              };

              return toReturn;
            });

            onSubmit(outGoing, setHasValidatedDataErrors);
          }}
          disabled={hasValidatedDataErrors || values(validity).some(i => !i) || loadingState.status === 'Loading'}
        >
          {t('submit')}
        </PrimaryButton>
      </BaseActions>
    </>
  );
};

const SequenceFilesModal = ({
  data,
  configuredTransition,
}: {
  data: ReadonlyArray<SampleTrackingCheckByFastqValidationResultPayload>;
  configuredTransition: ConfiguredTransition;
}) => {
  const { t } = UseMemoTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <DialogOpenButton title={t('sequenceFiles')} onClick={() => setModalOpen(true)}>
        <FullscreenOutlinedIcon />
      </DialogOpenButton>
      <BaseModal maxWidth={'lg'} open={modalOpen}>
        <BaseHeader
          title={t('sequenceFiles')}
          onClose={() => setModalOpen(false)}
          sx={{ pb: 0 }}
          configuredTransition={configuredTransition}
        />
        <DialogContent sx={{ mt: 1 }}>
          <CheckByAvailableSequenceFilesGrid {...{ data }} />
        </DialogContent>
      </BaseModal>
    </>
  );
};

const SupplementalFilesActions = ({
  supplementalFileProps,
}: {
  supplementalFileProps: UseState<SupplementalFile[]>;
}) => {
  const [supplementalFiles, setSupplementalFiles] = supplementalFileProps;

  return (
    <Box>
      <Typography variant={'body1'}>
        <CreateSupplementalFilesModal
          onCreate={supplementalFile => setSupplementalFiles(prevState => [...prevState, supplementalFile])}
        />
        <span>{supplementalFiles.length > 0 && supplementalFiles.length + ' File(s) will be saved'}</span>
      </Typography>
    </Box>
  );
};

const ExhaustionActions = ({ parentExhaustionProps }: { parentExhaustionProps: UseState<ParentExhaustion> }) => {
  const { t } = UseMemoTranslation();

  const [parentExhaustion, setParentExhaustion] = parentExhaustionProps;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 1 }}>
      <Typography variant={'subtitle1'}>{t('exhaustInfoIdMapping')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 0 }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={parentExhaustion !== 'discard' && parentExhaustion !== 'none'}
              checked={parentExhaustion === 'discard'}
              onChange={() => setParentExhaustion(parentExhaustion === 'discard' ? 'none' : 'discard')}
            />
          }
          label={t('discardOriginal')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={parentExhaustion !== 'exhaust' && parentExhaustion !== 'none'}
              checked={parentExhaustion === 'exhaust'}
              onChange={() => setParentExhaustion(parentExhaustion === 'exhaust' ? 'none' : 'exhaust')}
            />
          }
          label={t('exhaustOriginal')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={parentExhaustion !== 'rebiobank' && parentExhaustion !== 'none'}
              checked={parentExhaustion === 'rebiobank'}
              onChange={() => setParentExhaustion(parentExhaustion === 'rebiobank' ? 'none' : 'rebiobank')}
            />
          }
          label={t('rebiobankOriginal')}
        />
      </Box>
    </Box>
  );
};

const BaseModal = ({
  maxWidth,
  children,
  open = true,
}: {
  maxWidth: WidthSize;
  children: React.ReactNode;
  open?: boolean;
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth}>
      {children}
    </Dialog>
  );
};

const BaseHeader = ({
  transitionEnum,
  onClose,
  children,
  sx,
  title,
  configuredTransition,
}: {
  transitionEnum?: TransitionEnum;
  onClose: OnCloseFunction;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  title?: string;
  configuredTransition: ConfiguredTransition;
}) => {
  const { t } = UseMemoTranslation();

  function getInfoText() {
    if (!configuredTransition) {
      return '';
    }

    switch (configuredTransition.sampleActionOnCheckBy) {
      case 'notSupported':
        return t('sampleIdMappingTextFieldInfoNotSupported');
      case 'supportsOutputSamples':
        return t('sampleIdMappingTextFieldInfoNotAlwaysMakeNewSample');
      case 'requiresOutputSamples':
        return t('sampleIdMappingTextFieldInfoAlwaysMakeNewSample');
    }
  }

  return (
    <DialogTitle sx={sx}>
      <DialogCloseButton onClick={() => onClose(false)} />
      {title ?? t('recordQc')}
      {transitionEnum && <DialogTypography variant='subtitle1'>{getInfoText()}</DialogTypography>}
      {children}
    </DialogTitle>
  );
};

const BaseActions = ({
  loadingState,
  onClose,
  children,
  leftChildren,
}: {
  loadingState: LoadingState;
  onClose: OnCloseFunction;
  children?: React.ReactNode;
  leftChildren?: React.ReactNode;
}) => {
  return (
    <>
      <LoadingIndicator loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
      <DialogActions>
        <FlexBox
          sx={{
            alignItems: 'center',
            flexDirection: 'reverse',
            justifyContent: 'space-between',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {leftChildren ?? <Box></Box>}
          <Box>
            <CancelButton onClick={() => onClose(false)} />
            {children}
          </Box>
        </FlexBox>
      </DialogActions>
    </>
  );
};

function getRowErrorMessages(
  row: SampleTrackingIdMapping,
  config: CheckByConfiguration,
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>
) {
  let errorMessages: IdMappingErrorMessageType[] = [];

  if (config.getErrorMessages !== undefined) {
    errorMessages.push(...config.getErrorMessages(row, config, checkByFailedReasons));
  }

  if (!row.sampleIdentifier) {
    errorMessages.push('missingOriginalId');
  }

  if (config.requireLabAssignedId && !row.labAssignedSampleId) {
    errorMessages.push('missingNewLabAssignedId');
  }

  if (!row.qualityCheckStatus) {
    errorMessages.push('missingQualityCheckStatus');
  } else if (!PersistableQualityCheckStatuses.includes(row.qualityCheckStatus as QualityCheckStatus)) {
    errorMessages.push('invalidQualityCheckStatus');
  }

  if (config.supportsCheckByFailedReason && row.checkByFailedReason) {
    const checkByFailedReason = find(
      checkByFailedReasons,
      i =>
        i.name.toLowerCase() === row.checkByFailedReason?.toLowerCase() ||
        i.displayName.toLowerCase() === row.checkByFailedReason?.toLowerCase()
    );

    if (!checkByFailedReason) {
      errorMessages.push('invalidCheckByFailedReason');
    }
  }

  if (!row.containerIdentifier?.trim() && row.containerSamplePosition?.trim()) {
    errorMessages.push('missingContainerIdentifierNotMissingPosition');
  } else if (row.containerIdentifier?.trim() && !row.containerSamplePosition?.trim()) {
    errorMessages.push('missingContainerPositionNotMissingIdentifier');
  } else if (!row.containerIdentifier?.trim() && !row.containerSamplePosition?.trim() && row.containerType?.trim()) {
    errorMessages.push('missingContainerIdentifierAndPositionNotMissingType');
  }

  return errorMessages;
}
