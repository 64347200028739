import { StateCreator } from 'zustand';
import { AccessTokenSlice } from './accessTokenSlice';
import { GetResearchProjectPatientData, PatientApprovalPatientData } from '../../../../data/PatientApprovalData';
import { initialLoadableData, LoadableData, loadedData, trackLoading } from '../../utils/loadingUtils';

export interface PatientDataSlice {
  patientData: LoadableData<PatientApprovalPatientData>;
  setPatientData: (patientData: PatientApprovalPatientData | undefined) => void;
  loadPatientDataFromServer: (researchProjectId: string, deidentity: string) => void;
}

export const createPatientDataSlice: StateCreator<AccessTokenSlice & PatientDataSlice, [], [], PatientDataSlice> = (
  set,
  get
) => ({
  patientData: initialLoadableData(),
  setPatientData: patientData => set({ patientData: loadedData(patientData) }),
  loadPatientDataFromServer: (researchProjectId: string, deidentity: string) => {
    const accessToken = get().accessToken;

    if (accessToken === undefined) {
      return;
    }

    trackLoading(
      () => GetResearchProjectPatientData(researchProjectId, deidentity, accessToken),
      state => set({ patientData: state })
    );
  },
});
