import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import {
  copyBtn,
  createdAt,
  enteredAt,
  exitedAt,
  qualityCheckStatus,
  researchProject,
  sampleId,
  sampleIdentifier,
  stGridSampleAvailability,
  stGridSampleSampleTypeId,
  transition,
  wallClock,
} from '../../util/Constants';
import { makeStyles } from '@mui/styles';
import { Transition, TransitionSampleDetailsDto } from '../../data/SampleTrackingTransitionData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { Box } from '@mui/material';
import { Dictionary, UseStateSetter } from '../../util/TypeUtil';
import { TransitionSample } from '../../data/SampleTrackingData';
import { dateGetter, sampleTrackingGridDefaultColumnOverrides } from '../../util/grid/TableUtils';
import { GridCellCallBack } from '../../components/grid/GridCellCallback';
import { keyBy } from 'lodash';
import { Sample } from '../../data/SampleData';
import { SampleJourneyTransitionInformation } from '../../data/SampleJourneyData';
import { LoadingProps } from '../../components/LoadingStateUtil';
import { SampleTrackingGridWrapper } from '../../components/grid/SampleTrackingGridWrapper';
import { renderCopyTextGridCell } from '../../components/grid/cell/CopyTextGridCell';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';
import { TextOverflowBox } from 'components/TextOverflowBox';

export interface SampleJourneyHistoryGridProps {
  researchProjectId: string;
  identifiersByTransitionSampleId: Dictionary<string>;
  transitionSample: ReadonlyArray<TransitionSampleDetailsDto>;
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>;
  selectedSampleIds: ReadonlyArray<string>;
  setSelectedSampleIds: UseStateSetter<ReadonlyArray<string>>;
  filterOutInputOrders: boolean;
  showGlobalHistory: boolean;
  samples: ReadonlyArray<Sample>;
  configuredTransitions: ReadonlyArray<ConfiguredTransition>;
  loadingProps: LoadingProps;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  leafSampleIds: ReadonlyArray<string>;
}

export type Row = {
  id: number;
  sampleIdentifier: string;
  transitionSampleCreatedIn?: string;
  qualityCheckCreatedAt?: Date;
  researchProject: string;
} & Transition &
  TransitionSample &
  Sample;

const useStyles = makeStyles({
  highlightedRow: {
    backgroundColor: 'rgb(231, 221, 255)',
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'inherit !important',
      pointerEvents: 'none',
    },
  },
});

export const SampleJourneyHistoryGrid = ({
  researchProjectId,
  identifiersByTransitionSampleId,
  transitionSample,
  transitionInformation,
  selectedSampleIds,
  setSelectedSampleIds,
  filterOutInputOrders,
  showGlobalHistory,
  samples,
  configuredTransitions,
  loadingProps,
  rightActions,
  leftActions,
  leafSampleIds,
}: SampleJourneyHistoryGridProps) => {
  const classes = useStyles();
  const apiRef = useGridApiRef();

  const columns = useColumns(selectedSampleIds, identifiersByTransitionSampleId, setSelectedSampleIds);
  const rows = useRows(
    researchProjectId,
    transitionSample,
    identifiersByTransitionSampleId,
    filterOutInputOrders,
    showGlobalHistory,
    transitionInformation,
    samples,
    configuredTransitions,
    leafSampleIds
  );

  return (
    <SampleTrackingGridWrapper
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            foreignHash: false,
            sampleBbid: false,
          },
        },
        sorting: {
          sortModel: [
            { field: createdAt, sort: 'asc' },
            { field: enteredAt, sort: 'asc' },
          ],
        },
      }}
      defaultGroupingExpansionDepth={100}
      disableRowSelectionOnClick
      getRowClassName={params =>
        selectedSampleIds && selectedSampleIds.includes(params.row.sampleId) ? classes.highlightedRow : classes.noHover
      }
      leftActions={leftActions}
      rightActions={rightActions}
      loadingProps={loadingProps}
      hideLoadingIndicator
      defaultColumnOrdering={[
        copyBtn,
        sampleId,
        researchProject,
        transition,
        qualityCheckStatus,
        enteredAt,
        exitedAt,
        stGridSampleSampleTypeId,
        stGridSampleAvailability,
      ]}
      cacheKey={'sample-tracking-journey-history-grid'}
      columnDefinitionOverrides={sampleTrackingGridDefaultColumnOverrides}
    />
  );
};

const useRows = (
  researchProjectId: string,
  transitionSampleData: ReadonlyArray<TransitionSampleDetailsDto>,
  identifiersByTransitionSampleId: Dictionary<string>,
  filterOutInputOrders: boolean,
  showGlobalHistory: boolean,
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>,
  samples: ReadonlyArray<Sample>,
  configuredTransitions: ReadonlyArray<ConfiguredTransition>,
  leafSampleIds: ReadonlyArray<string>
): ReadonlyArray<Row> => {
  return useMemo(() => {
    if (transitionSampleData.length === 0) {
      return [];
    }

    const informationBySampleId = keyBy(transitionInformation, t => t.sampleId);
    const samplesBySampleId = keyBy(samples, t => t.sampleId);
    const transitionById = keyBy(configuredTransitions, i => i.configuredTransitionId);

    const data: Row[] = transitionSampleData
      .filter(r => (showGlobalHistory ? true : r.researchProject.researchProjectId === researchProjectId))
      .map((r, index) => {
        const information = informationBySampleId[r.transitionSample.sampleId];
        const sample = samplesBySampleId[r.transitionSample.sampleId];

        return {
          id: index,
          sampleIdentifier: identifiersByTransitionSampleId[r.transitionSample.transitionSampleId],
          transitionSampleCreatedIn: information.transitionSampleCreatedIn?.transitionSampleId,
          qualityCheckCreatedAt: information.transitionSampleCreatedIn?.qualityCheckCreatedAt,
          researchProject: r.researchProject.name,
          ...r.transitionSample,
          ...r.transition,
          ...sample,
          transition: transitionById[r.transition.configuredTransitionId ?? '']?.displayName ?? r.transition.transition,
          isCurrent: r.transitionSample.transitionSampleId === information.currentTransitionSample?.transitionSampleId,
          isLeaf: leafSampleIds.includes(sample.sampleId),
        };
      })
      // Show all transitions the sample has exited. The current. The root.
      .filter(
        i =>
          // If filter is disabled then pass all through
          !filterOutInputOrders ||
          // if sample has exited order then show
          i.exitedAt ||
          // if sample is the latest transition then show
          (i.isCurrent && i.isLeaf)
      );

    return data;
  }, [
    researchProjectId,
    transitionSampleData,
    identifiersByTransitionSampleId,
    filterOutInputOrders,
    showGlobalHistory,
    transitionInformation,
    samples,
    configuredTransitions,
    leafSampleIds,
  ]);
};

const useColumns = (
  selectedSampleIds: ReadonlyArray<string>,
  identifiersBySampleId: Dictionary<string>,
  setSelectedSampleIds: UseStateSetter<ReadonlyArray<string>>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    if (Object.keys(identifiersBySampleId).length === 0) {
      return [];
    }

    return [
      {
        field: copyBtn,
        headerName: t(copyBtn),
        headerAlign: 'center',
        align: 'center',
        maxWidth: 30,
        renderCell: renderCopyTextGridCell,
        valueGetter: params => params.row.sampleIdentifier,
      },
      {
        field: sampleId,
        headerName: t(sampleIdentifier),
        headerAlign: 'left',
        align: 'left',
        minWidth: 250,
        cellClassName: 'monospace-font',
        renderCell: params => {
          const { value, row } = params;
          const sampleId = row?.sampleId;

          return (
            <Box sx={{ fontFamily: 'monospace', display: 'inline-block', width: 'inherit' }}>
              {selectedSampleIds.includes(sampleId) ? (
                <TextOverflowBox value={value} />
              ) : (
                <GridCellCallBack
                  text={value}
                  callBack={() => {
                    setSelectedSampleIds([sampleId]);
                  }}
                  sx={{ paddingLeft: 0, minWidth: 0, fontFamily: 'monospace' }}
                />
              )}
            </Box>
          );
        },
        valueGetter: params => params.row.sampleIdentifier,
        key: selectedSampleIds,
      },
      {
        field: researchProject,
        headerName: t(researchProject),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
      },
      {
        field: transition,
        headerName: t(transition),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: qualityCheckStatus,
        headerName: t(qualityCheckStatus),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: enteredAt,
        headerName: t(enteredAt),
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
        type: 'date',
        valueGetter: dateGetter,
      },
      {
        field: exitedAt,
        headerName: t(exitedAt),
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
        type: 'date',
        valueGetter: dateGetter,
      },
      {
        field: createdAt,
        headerName: t(wallClock),
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
        type: 'date',
        valueGetter: dateGetter,
      },
    ];
  }, [t, selectedSampleIds, identifiersBySampleId, setSelectedSampleIds]);
};
