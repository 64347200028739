import './App.css';
import './i18n/Config';
import { BiobankMetricsPage } from './pages/BiobankMetricsPage';
import { LabMetricsPage } from 'pages/LabMetricsPage';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { TokenizationPage } from './pages/TokenizationPage';
import { HomePage } from './pages/HomePage';
import { LoginButton } from './auth/LoginButton';
import { SignInPage } from './auth/SignInPage';
import { SignOutPage } from './auth/SignOutPage';
import useAuth from 'auth/UseAuth';
import { LicenseInfo } from '@mui/x-license-pro';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { Sidebar } from 'layout/sidebar/Sidebar';
import { SnackbarProvider } from 'notistack';
import SnackbarUtils, { SnackbarUtilsConfigurator } from 'notifications/SnackbarUtils';
import React, { useEffect } from 'react';
import { appSettings } from 'AppSettings';
import { ResearchProjectManagementPage } from './pages/ResearchProjectManagementPage';
import { ResearchProjectCohortPage } from './pages/ResearchProjectCohortPage';
import { PathogenGroupPage } from 'pages/PathogenGroupPage';
import { DiseaseAreaMetricsPage } from './pages/DiseaseAreaMetricsPage';
import { MigrationsPage } from './pages/MigrationsPage';
import { DiseaseAreasPage } from './pages/DiseaseAreasPage';
import { BbidGeneratorPage } from './pages/BbidGeneratorPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PipelineExecutionPage } from './superDuperFiesta/PipelineExecutionPage';
import { PipelineReviewPage } from './superDuperFiesta/PipelineReviewPage';
import { PipelineRulesPage } from './superDuperFiesta/PipelineRulesPage';
import { FlexBox } from './components/FlexBox';
import { PageHeaderProvider } from './contexts/PageHeaderContext';
import { SuperDuperFiestaMigrationsPage } from './superDuperFiesta/SuperDuperFiestaMigrationsPage';
import { DataProductsPage } from './pages/DataProductsPage';
import { DataProductPage } from './pages/DataProductPage';
import { DataSchemaPage } from './pages/DataSchemaPage';

const queryClient = new QueryClient();

export const App = () => {
  const { isAuthenticated, isLoading, user } = useAuth();

  LicenseInfo.setLicenseKey(appSettings.license.muiX);

  useEffect(() => {
    const eventSource = new EventSource(
      `${appSettings.api.endpoint}/api/v2/Notifications?username=${encodeURI(user?.name ?? '')}`,
      { withCredentials: true }
    );
    eventSource.onmessage = e => {
      const obj = JSON.parse(e.data);
      SnackbarUtils.toast(obj.Message, obj.Type.toLowerCase());
    };
    return () => {
      eventSource.close();
    };
  }, [user?.name]);

  if (isLoading) {
    return <></>;
  } else if (isAuthenticated) {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <PageHeaderProvider>
            <CssBaseline />
            <FlexBox>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                <SnackbarUtilsConfigurator />
                <Router>
                  <Sidebar />
                  <Switch>
                    <Route path='/' exact component={HomePage} />

                    <Route path='/biobank-metrics' component={BiobankMetricsPage} />

                    <Route path='/lab-metrics' component={LabMetricsPage} />

                    <Route path='/pathogens' component={PathogenGroupPage} />

                    <Route path='/disease-area-metrics' component={DiseaseAreaMetricsPage} />

                    <Route path='/disease-areas' component={DiseaseAreasPage} />

                    <Route exact path='/research-project' component={ResearchProjectManagementPage} />

                    <Route path='/research-project/:researchProjectId' component={ResearchProjectCohortPage} />

                    <Route exact path='/data-products' component={DataProductsPage} />

                    <Route exact path='/data-products/:dataProductId' component={DataProductPage} />

                    <Route exact path='/data-products/schemas/:dataSchemaId' component={DataSchemaPage} />

                    <Route exact path='/sdf-pipelines' component={PipelineExecutionPage} />

                    <Route exact path='/sdf-pipeline-rules' component={PipelineRulesPage} />

                    <Route path='/sdf-migrations' component={SuperDuperFiestaMigrationsPage} />

                    <Route exact path='/sdf-pipeline-review' component={PipelineReviewPage} />

                    <Route path='/bbidgen' component={BbidGeneratorPage} />

                    <Route path='/migrations' component={MigrationsPage} />

                    <Route path='/tokenization' component={TokenizationPage} />

                    <Route path='/signin'>
                      <SignInPage action='signin' />
                    </Route>

                    <Route path='/signin-callback'>
                      <SignInPage action='signin-callback' />
                    </Route>

                    <Route path='/signout'>
                      <SignOutPage action='signout' />
                    </Route>

                    <Route path='/signout-callback'>
                      <SignOutPage action='signout-callback' />
                    </Route>

                    <Redirect to='/' />
                  </Switch>
                </Router>
              </SnackbarProvider>
            </FlexBox>
          </PageHeaderProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );
  } else {
    return (
      <div style={{ margin: 30 }}>
        Login: <LoginButton />
      </div>
    );
  }
};
