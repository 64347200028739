import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetSampleTrackingTransitionSamples,
  SampleTrackingTableData,
  TransitionSample,
  TransitionType,
} from '../../data/SampleTrackingData';
import { CreateSampleTrackingTransitionModal } from './CreateSampleTrackingTransitionModal';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import {
  dateGetter,
  generateWidthFromProperty,
  sampleTrackingGridDefaultColumnOverrides,
} from '../../util/grid/TableUtils';
import {
  checkByFailedReason,
  compactGridHeaderClassName,
  copyBtn,
  createdAt,
  enteredAt,
  exitedAt,
  foreignHash,
  informaticsRunStatus,
  inputForeignHash,
  inputId,
  inputLabAssignedId,
  inputSampleBbid,
  inputSampleId,
  labAssignedSampleId,
  multiQc,
  newForeignHash,
  newId,
  newLabAssignedId,
  newSampleBbid,
  newSampleId,
  qualityCheckStatus,
  r1FastQLocation,
  r2FastQLocation,
  sampleBbid,
  sampleId,
  sequenceRunId,
  sequenceType,
  stGridResearchProjectSampleSelectionStatus,
  stGridSampleSampleTypeId,
  transitionId,
  wallClock,
} from '../../util/Constants';
import { getTransitionSampleIdentifier, Sample, truncateNoDashGuid } from '../../data/SampleData';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { SampleTrackingRowSelectionModal } from './SampleTrackingRowSelectionModal';
import { filter, find, flatMap, keyBy, map, uniq } from 'lodash';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import { GetOrderScopedSampleTrackingTableData } from '../../data/SampleTrackingTransitionData';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { InformaticsCheckByModal } from './InformaticsCheckByModal';
import { renderCellMultiQcDownloadButton } from 'components/grid/GridCellMultiQcDownloadButton';
import { FlexTableBox } from 'components/FlexTableBox';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import { CheckByFailedReason } from 'data/ReferenceData';
import { SampleTrackingExhaustModal } from './SampleTrackingExhaustModal';
import {
  GetSampleSequencingJourneyDetails,
  SampleSequencingJourneyDetails,
  SequencingFastQ,
} from 'data/SampleJourneyData';
import { SampleTrackingOrderGridDetailsModal } from './orders/SampleTrackingOrderDetailsModal';
import { SampleTrackingOrderRow } from './orders/SampleTrackingOrderGrid';
import { FlexBox } from '../../components/FlexBox';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import { CheckByModal } from './CheckByModal';
import { SampleTrackingCountsKpi } from '../../components/SampleTrackingCountsKpi';
import useCheckByFailedReasons from '../../components/hooks/UseCheckByFailedReasons';
import { useTransitionEnum } from '../../components/hooks/UseTransitionEnums';
import { renderGridCellInformaticsRunningIndicator } from '../../components/grid/GridCellInformaticsRunningIndicator';
import { DecryptForeignHashesSwitch } from '../../components/DecryptForeignHashesSwitch';
import { RefreshableComponentWrapper } from '../../components/RefreshableComponentWrapper';
import { SampleTrackingGridWrapper } from '../../components/grid/SampleTrackingGridWrapper';
import { GetQualityCheckGroupDetails } from '../../data/SampleTrackingQualityCheckGroupData';
import { renderCopyTextGridCell } from '../../components/grid/cell/CopyTextGridCell';

interface BaseGridProps {
  researchProjectId: string;
  transitionType: TransitionType;
}

export interface TransitionRecordGridProps extends BaseGridProps {
  sampleGroup?: string;
  configuredTransitionId: string;
}

export interface TransitionSampleOrderScopedGridProps extends BaseGridProps {
  transitionIds: ReadonlyArray<string>;
  orderDetails: SampleTrackingOrderRow;
}

export interface TransitionSampleQualityCheckGroupScopedGridProps extends BaseGridProps {
  qualityCheckGroupId: string;
}

interface GridProps {
  researchProjectId: string;
  transitionType: TransitionType;
  configuredTransitionId?: string;
  getData: (accessToken: string, useDecryptedHashes: boolean) => Promise<ReadonlyArray<SampleTrackingTableData>>;
  postDataFilter?: (rows: Row[]) => Row[];
  disableActions?: boolean;
  filterInactiveTransitionSamples?: boolean;
  columnsToHide?: ReadonlyArray<string>;
  customActions?: ReactNode;
  customKpis?: ReactNode;
}

type Row = Sample &
  TransitionSample &
  SampleSequencingJourneyDetails & {
    groupByOrderId?: string[];
    groupByParent?: string[];
    id: number;
    newForeignHash?: string;
    newId?: string;
    newLabAssignedId?: string;
    newSampleBbid?: string;
    newSampleId?: string;
    inputForeignHash?: string;
    inputId: string;
    inputLabAssignedId?: string;
    inputSampleBbid?: string;
    inputSampleId?: string;
    filterId: string;
    selectionStatus?: string;
  };

const useStyles = makeStyles({
  disabledRow: {
    backgroundColor: grey[200],
  },
});

export const TransitionSampleGrid = ({
  researchProjectId,
  transitionType,
  sampleGroup,
  configuredTransitionId,
}: TransitionRecordGridProps) => {
  return (
    <>
      <Grid
        researchProjectId={researchProjectId}
        transitionType={transitionType}
        getData={async (accessToken: string, useDecryptedHashes: boolean) => {
          return await GetSampleTrackingTransitionSamples(
            researchProjectId,
            accessToken,
            configuredTransitionId,
            useDecryptedHashes,
            sampleGroup
          );
        }}
        configuredTransitionId={configuredTransitionId}
        columnsToHide={[exitedAt]}
        customKpis={
          <SampleTrackingCountsKpi
            researchProjectId={researchProjectId}
            configuredTransitionId={configuredTransitionId}
            sampleGroup={sampleGroup ?? 'all'}
          />
        }
      />
    </>
  );
};

export const TransitionSampleOrderScopedGrid = ({
  researchProjectId,
  transitionType,
  transitionIds,
  orderDetails,
}: TransitionSampleOrderScopedGridProps) => {
  return (
    <>
      <Grid
        researchProjectId={researchProjectId}
        transitionType={transitionType}
        getData={async (accessToken: string, useDecryptedHashes: boolean) => {
          const data = await Promise.all(
            transitionIds.map(
              async id =>
                await GetOrderScopedSampleTrackingTableData(id, researchProjectId, useDecryptedHashes, accessToken)
            )
          );

          const flattenedData = flatMap(data);
          const allTransitionSampleIds = map(flattenedData, i => i.transitionSample.transitionSampleId);

          // Filter out samples that were created and later added to the order
          return filter(
            flatMap(data),
            i => !i.parentTransitionSampleId || !allTransitionSampleIds.includes(i.parentTransitionSampleId)
          );
        }}
        disableActions={true}
        filterInactiveTransitionSamples={false}
        columnsToHide={[exitedAt, enteredAt, qualityCheckStatus, checkByFailedReason, transitionId, newId]}
        customActions={<SampleTrackingOrderGridDetailsModal row={orderDetails} transitionType={transitionType} />}
      />
    </>
  );
};

export const TransitionSampleQualityCheckGroupScopedGrid = ({
  researchProjectId,
  transitionType,
  qualityCheckGroupId,
}: TransitionSampleQualityCheckGroupScopedGridProps) => {
  return (
    <>
      <Grid
        researchProjectId={researchProjectId}
        transitionType={transitionType}
        getData={async (accessToken: string, useDecryptedHashes: boolean) => {
          const qcScopedData = await GetQualityCheckGroupDetails(qualityCheckGroupId, accessToken);

          const transitionIds = qcScopedData.transitionIds;
          const transitionScopedData = await Promise.all(
            transitionIds.map(
              async id =>
                await GetOrderScopedSampleTrackingTableData(id, researchProjectId, useDecryptedHashes, accessToken)
            )
          );

          return flatMap(transitionScopedData);
        }}
        postDataFilter={(rows: Row[]): Row[] => {
          return rows.filter(row => row.transitionQualityCheckGroupId === qualityCheckGroupId);
        }}
        disableActions={true}
        filterInactiveTransitionSamples={false}
      />
    </>
  );
};

export const Grid = ({
  researchProjectId,
  transitionType,
  getData,
  postDataFilter,
  disableActions = false,
  filterInactiveTransitionSamples = true,
  columnsToHide,
  customActions,
  customKpis,
  configuredTransitionId,
}: GridProps) => {
  const { accessToken } = useAuth();
  const classes = useStyles();
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const [rawData, setRawData] = useState<ReadonlyArray<SampleTrackingTableData>>([]);

  const [selectedSampleIds, setSelectedSampleIds] = useState<string[]>([]);
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const [useFilterOutOriginal, setUseFilterOutOriginal] = useState<boolean>(true);
  const [groupByParent, setGroupByParent] = useState<boolean>(transitionType === 'Subsetting');
  const [groupByOrderId, setGroupByOrderId] = useState<boolean>(false);
  const [supportsGroupByParent] = useState<boolean>(transitionType === 'Subsetting');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sampleSequencingJourneyDetails, setSampleSequencingJourneyDetails] = useState<
    ReadonlyArray<SampleSequencingJourneyDetails>
  >([]);

  const transitionEnum = useTransitionEnum(transitionType);
  const checkByFailedReasons = useCheckByFailedReasons(transitionEnum?.transitionEnumId);

  const rows = useRows(
    rawData,
    sampleSequencingJourneyDetails,
    filterInactiveTransitionSamples,
    useFilterOutOriginal,
    postDataFilter
  );
  const columns = useColumns(transitionType, rawData, checkByFailedReasons, columnsToHide);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      const rows = await getData(accessToken, useDecryptedHashes);
      setRawData(rows);

      const sampleSequencingJourneyDetailsData = await GetSampleSequencingJourneyDetails(
        map(rows, i => {
          return { sampleId: i.sample.sampleId, sampleJourneyId: i.sampleJourneyId };
        }),
        accessToken
      );
      setSampleSequencingJourneyDetails(sampleSequencingJourneyDetailsData);
    });
  }, [accessToken, researchProjectId, refreshTrigger, useDecryptedHashes, getData]);

  useEffect(() => {
    if (selectionModel) {
      const sampleIds: string[] = [];
      selectionModel.forEach(selected => {
        const id = apiRef.current?.getRow(selected)?.sampleId;
        if (id) {
          sampleIds.push(id);
        }
      });

      setSelectedSampleIds(uniq(sampleIds));
    }
  }, [selectionModel, apiRef]);

  function refreshData(hasChanges: boolean) {
    if (!hasChanges) {
      return;
    }

    setRefreshTrigger(currentState => !currentState);
    setSelectionModel([]);
    setSelectedSampleIds([]);
  }

  const getInformaticsCheckByTableData = useCallback(() => {
    return map(rows, i => {
      return {
        sampleId: i.sampleId ?? '',
        transitionSampleId: i.transitionSampleId ?? '',
        sampleIdentifier: i.inputId ?? '',
        selectionStatus: i.selectionStatus ?? '',
        qualityCheckStatus: i.qualityCheckStatus ?? '',
        sequenceType: i.sequenceType ?? '',
        sequenceRunId: i.sequenceRunId ?? '',
        informaticsPipelineRunId: i.informaticsPipelineRunId ?? '',
      };
    });
  }, [rows]);

  return (
    <FlexTableBox>
      <AuthorizedSection hasSampleTrackingViewAccess>
        <FlexBox sx={{ flexDirection: 'column' }}>
          <RefreshableComponentWrapper refreshTrigger={refreshTrigger}>
            {customKpis ?? <></>}
          </RefreshableComponentWrapper>
        </FlexBox>
      </AuthorizedSection>
      <SampleTrackingGridWrapper
        apiRef={apiRef}
        leftActions={
          <FlexBox flexDirection='row' alignItems='center' flexWrap={'wrap'}>
            <DecryptForeignHashesSwitch decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]} />
            {!disableActions && (
              <CustomizableSwitch
                title={t('filterOutInputsWithAnOutput')}
                informationText={t('filterOutInputsWithAnOutputInfo')}
                checked={useFilterOutOriginal}
                onChange={() => {
                  setUseFilterOutOriginal(!useFilterOutOriginal);
                }}
              />
            )}
            {supportsGroupByParent && !disableActions && (
              <CustomizableSwitch
                title={t('groupByParent')}
                checked={groupByParent}
                onChange={() => {
                  setGroupByParent(!groupByParent);
                  if (!groupByParent) {
                    setGroupByOrderId(false);
                  }
                }}
              />
            )}
            {!disableActions && (
              <CustomizableSwitch
                title={t('groupByOrderId')}
                checked={groupByOrderId}
                onChange={() => {
                  setGroupByOrderId(!groupByOrderId);
                  if (!groupByOrderId) {
                    setGroupByParent(false);
                  }
                }}
              />
            )}
          </FlexBox>
        }
        rightActions={
          <FlexBox flexDirection='row-reverse' alignItems='center' flexWrap={'wrap'}>
            {!disableActions && (
              <AuthorizedSection hasSampleTrackingWriteAccess>
                <SampleTrackingExhaustModal selectedSampleIds={selectedSampleIds} onClose={refreshData} />
                <CreateSampleTrackingTransitionModal
                  researchProjectId={researchProjectId}
                  selectedSamples={map(selectedSampleIds, i => {
                    const item = rows.find(j => j.sampleId === i);

                    return { ...item, sampleIdentifier: item?.newId ?? item?.inputId ?? '' } as any;
                  })}
                  onClose={refreshData}
                />
                {transitionType === 'Informatics' && configuredTransitionId && !!rows && (
                  <InformaticsCheckByModal
                    researchProjectId={researchProjectId}
                    data={getInformaticsCheckByTableData()}
                    onClose={refreshData}
                    configuredTransitionId={configuredTransitionId}
                  />
                )}
                {transitionType !== 'Informatics' && configuredTransitionId && (
                  <CheckByModal
                    researchProjectId={researchProjectId}
                    transitionType={transitionType}
                    configuredTransitionId={configuredTransitionId}
                    onClose={refreshData}
                  />
                )}
                <SampleTrackingRowSelectionModal
                  gridApiRef={apiRef}
                  sampleIdProperties={[foreignHash, sampleId, labAssignedSampleId, sampleBbid]}
                  setSelectionModel={setSelectionModel}
                />
              </AuthorizedSection>
            )}
            <AuthorizedSection hasSampleTrackingWriteAccess>{customActions ?? <></>}</AuthorizedSection>
          </FlexBox>
        }
        rows={rows}
        columns={columns}
        initialState={{
          pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_FIELD, GRID_TREE_DATA_GROUPING_FIELD, copyBtn] },
          sorting: {
            sortModel: [
              { field: inputId, sort: 'asc' },
              { field: newId, sort: 'asc' },
              { field: sequenceRunId, sort: 'desc' },
              { field: qualityCheckStatus, sort: 'asc' },
              { field: enteredAt, sort: 'asc' },
            ],
          },
        }}
        checkboxSelection={!disableActions}
        getTreeDataPath={row => {
          if (groupByOrderId) {
            return row?.groupByOrderId;
          }

          if (groupByParent) {
            return row?.groupByParent;
          }

          return row?.groupByParent;
        }}
        treeData={groupByOrderId || groupByParent}
        groupingColDef={() => {
          if (groupByOrderId) {
            return {
              headerName: t('byOrderId'),
              minWidth: 150,
              maxWidth: 300,
              valueFormatter: params => {
                const node = params.api.getRowNode(params.id!)!;
                if (node.type !== 'group') {
                  return '';
                }

                return truncateNoDashGuid(node.groupingKey?.toString() ?? '');
              },
            };
          }

          if (groupByParent) {
            return {
              headerName: t('byParent'),
              minWidth: 300,
              maxWidth: 600,
              valueFormatter: params => {
                const node = params.api.getRowNode(params.id!)!;
                if (node.type !== 'group') {
                  return '';
                }

                return node.groupingKey;
              },
            };
          }

          return {
            headerName: t('unknown'),
            minWidth: 300,
          };
        }}
        defaultGroupingExpansionDepth={1}
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
        rowSelectionModel={selectionModel}
        getRowClassName={params => (!params.row.exitedAt ? '' : classes.disabledRow)}
        loadingProps={[loadingState, setLoadingState]}
        defaultColumnOrdering={[
          copyBtn,
          inputId,
          newId,
          sequenceType,
          sequenceRunId,
          informaticsRunStatus,
          r1FastQLocation,
          r2FastQLocation,
          qualityCheckStatus,
          checkByFailedReason,
          stGridResearchProjectSampleSelectionStatus,
          stGridSampleSampleTypeId,
          enteredAt,
          exitedAt,
          transitionId,
        ]}
        cacheKey={`sample-tracking-${configuredTransitionId}-sample-grid`}
        columnDefinitionOverrides={sampleTrackingGridDefaultColumnOverrides}
        refreshTrigger={refreshTrigger}
      />
    </FlexTableBox>
  );
};

const useRows = (
  data: ReadonlyArray<SampleTrackingTableData>,
  sampleSequencingJourneyDetails: ReadonlyArray<SampleSequencingJourneyDetails>,
  filterInactiveTransitionSamples: boolean,
  useFilterOutOriginal: boolean,
  postDataFilter: ((rows: Row[]) => Row[]) | undefined
) => {
  return useMemo(() => {
    const dataByTransitionId = keyBy(data, r => r.transitionSample.transitionSampleId);
    const sampleSequencingJourneyBySampleId = keyBy(sampleSequencingJourneyDetails, r => r.sampleId);

    const originalIdsToFilter: string[] = uniq(
      data.map(i => dataByTransitionId[i.parentTransitionSampleId ?? '']?.sample.sampleId).filter(i => i)
    );

    let rows: Row[] = data
      .filter(r => !filterInactiveTransitionSamples || !r.transitionSample.exitedAt)
      .map((r, index) => {
        // Check if sample has an antecedent
        const originalRow = dataByTransitionId[r.parentTransitionSampleId ?? ''];

        const identifiers = !originalRow
          ? {
              filterId: r.sample.sampleId,
              inputSampleId: r.sample.sampleId,
              inputId: getTransitionSampleIdentifier(r.labAssignedSampleLabel, r.sample),
              inputSampleBbid: r.sample.sampleBbid,
              inputForeignHash: r.sample.foreignHash,
              inputLabAssignedId: r.labAssignedSampleLabel?.label ?? '',
              groupByParent: [getTransitionSampleIdentifier(r.labAssignedSampleLabel, r.sample)],
              groupByOrderId: [r.transitionSample.transitionId, r.transitionSample.transitionSampleId],
            }
          : {
              filterId: '',
              inputSampleId: originalRow.sample.sampleId,
              newSampleId: r.sample.sampleId,
              inputId: getTransitionSampleIdentifier(originalRow.labAssignedSampleLabel, originalRow.sample),
              newId: getTransitionSampleIdentifier(r.labAssignedSampleLabel, r.sample),
              inputSampleBbid: originalRow.sample.sampleBbid,
              inputForeignHash: originalRow.sample.foreignHash,
              inputLabAssignedId: originalRow.labAssignedSampleLabel?.label ?? '',
              newSampleBbid: r.sample.sampleBbid,
              newForeignHash: r.sample.foreignHash,
              newLabAssignedId: r.labAssignedSampleLabel?.label,
              groupByParent: [
                getTransitionSampleIdentifier(originalRow.labAssignedSampleLabel, originalRow.sample),
                getTransitionSampleIdentifier(r.labAssignedSampleLabel, r.sample),
              ],
              groupByOrderId: [r.transitionSample.transitionId, r.transitionSample.transitionSampleId],
            };

        // check if antecedent is in the same order
        return {
          id: index,
          ...sampleSequencingJourneyBySampleId[r.sample.sampleId ?? ''],
          ...identifiers,
          ...r.sample,
          ...r.transitionSample,
          labAssignedSampleId: r.labAssignedSampleLabel?.label,
        };
      })
      .filter(r => !useFilterOutOriginal || !originalIdsToFilter.includes(r.filterId));

    if (postDataFilter) {
      rows = postDataFilter(rows);
    }

    return rows;
  }, [data, filterInactiveTransitionSamples, useFilterOutOriginal, sampleSequencingJourneyDetails, postDataFilter]);
};

const useColumns = (
  transitionType: TransitionType,
  data: ReadonlyArray<SampleTrackingTableData>,
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>,
  columnsToHide: ReadonlyArray<string> | undefined
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const sequencingCols: GridColDef[] =
      transitionType === 'Sequencing' || transitionType === 'Informatics'
        ? [
            {
              field: r1FastQLocation,
              headerName: t(r1FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueGetter: params =>
                find(params.row.fastQs, i => (i as SequencingFastQ).type.name === 'r1')?.file.location,
            },
            {
              field: r2FastQLocation,
              headerName: t(r2FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 2,
              valueGetter: params =>
                find(params.row.fastQs, i => (i as SequencingFastQ).type.name === 'r2')?.file.location,
            },
          ]
        : [];

    const informaticsCols: GridColDef[] =
      transitionType === 'Informatics'
        ? [
            {
              field: sequenceType,
              headerName: t(sequenceType),
              headerClassName: compactGridHeaderClassName,
              width: 100,
              headerAlign: 'center',
              align: 'center',
              valueFormatter: ({ value }) => (value ? value.toUpperCase() : value),
            },
            {
              field: sequenceRunId,
              headerName: t(multiQc),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'center',
              width: 100,
              filterable: false,
              sortable: false,
              renderCell: renderCellMultiQcDownloadButton,
            },
            {
              field: informaticsRunStatus,
              headerName: t(informaticsRunStatus),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'center',
              width: 100,
              filterable: false,
              sortable: false,
              valueGetter: params => params.row.sequenceRunId,
              renderCell: renderGridCellInformaticsRunningIndicator,
            },
          ]
        : [];

    return (
      [
        {
          field: copyBtn,
          headerName: t(copyBtn),
          headerAlign: 'center',
          align: 'center',
          maxWidth: 30,
          renderCell: renderCopyTextGridCell,
          valueGetter: params => params.row.newId ?? params.row.inputId,
        },
        {
          field: inputId,
          headerName: t(inputId),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 250,
          minWidth: 250,
          renderCell: params =>
            renderSampleHierarchyGridCell({
              ...params,
              sampleId: params.row.inputSampleId,
              displayValue: params.value,
            }),
        },
        {
          field: newId,
          headerName: t(newId),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 250,
          minWidth: 250,
          renderCell: params =>
            renderSampleHierarchyGridCell({
              ...params,
              sampleId: params.row.newSampleId,
              displayValue: params.value,
            }),
        },
        {
          field: inputSampleBbid,
          headerName: t(inputSampleBbid),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
          renderCell: params =>
            renderSampleHierarchyGridCell({
              ...params,
              sampleId: params.row.sampleId,
              displayValue: params.value,
            }),
        },
        {
          field: inputForeignHash,
          headerName: t(inputForeignHash),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
          renderCell: params =>
            renderSampleHierarchyGridCell({
              ...params,
              sampleId: params.row.sampleId,
              displayValue: params.value,
            }),
        },
        {
          field: inputLabAssignedId,
          headerName: t(inputLabAssignedId),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
        },
        {
          field: inputSampleId,
          headerName: t(inputSampleId),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
        },
        {
          field: newSampleBbid,
          headerName: t('daughterSampleBbid'),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
          renderCell: params =>
            renderSampleHierarchyGridCell({
              ...params,
              sampleId: params.row.sampleId,
              displayValue: params.value,
            }),
        },
        {
          field: newForeignHash,
          headerName: t('daughterForeignHash'),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
          renderCell: params =>
            renderSampleHierarchyGridCell({
              ...params,
              sampleId: params.row.sampleId,
              displayValue: params.value,
            }),
        },
        {
          field: newLabAssignedId,
          headerName: t('daughterLabAssignedId'),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
        },
        {
          field: newSampleId,
          headerName: t('daughterSampleId'),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: 300,
        },
        ...informaticsCols,
        ...sequencingCols,
        {
          field: qualityCheckStatus,
          headerName: t(qualityCheckStatus),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'center',
          align: 'center',
          width: 200,
          valueFormatter: ({ value }) => value && t(value),
        },
        {
          field: checkByFailedReason,
          headerName: t(checkByFailedReason),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'left',
          align: 'left',
          width: generateWidthFromProperty(
            data ?? [],
            200,
            (row: SampleTrackingTableData) => row.transitionSample.checkByFailedReason
          ),
          valueFormatter: ({ value }) => value && find(checkByFailedReasons, i => i.name === value)?.displayName,
        },
        {
          field: enteredAt,
          headerName: t(enteredAt),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'center',
          align: 'center',
          minWidth: 200,
          type: 'date',
          valueGetter: dateGetter,
        },
        {
          field: exitedAt,
          headerName: t(exitedAt),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'center',
          align: 'center',
          minWidth: 200,
          type: 'date',
          valueGetter: dateGetter,
        },
        {
          field: createdAt,
          headerName: t(wallClock),
          headerAlign: 'left',
          align: 'left',
          minWidth: 100,
          type: 'date',
          valueGetter: dateGetter,
        },
        {
          field: transitionId,
          headerName: t(transitionId),
          headerClassName: compactGridHeaderClassName,
          headerAlign: 'center',
          align: 'center',
          width: 150,
          renderCell: renderGuidCellTooltip,
        },
      ] as GridColDef[]
    ).filter(i => !columnsToHide?.includes(i.field)) as GridColDef[];
  }, [t, transitionType, data, checkByFailedReasons, columnsToHide]);
};
