import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetDataSources, SuperDuperFiestaDataSource } from '../../data/SuperDuperFiestaData';

const useSuperDuperFiestaDataSources = (): ReadonlyArray<SuperDuperFiestaDataSource> => {
  const { accessToken } = useAuth();
  const [SuperDuperFiestaDataSources, setSuperDuperFiestaDataSources] = useState<
    ReadonlyArray<SuperDuperFiestaDataSource>
  >([]);

  useEffect(() => {
    let isCancelled = false;

    GetDataSources(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setSuperDuperFiestaDataSources(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return SuperDuperFiestaDataSources;
};

export default useSuperDuperFiestaDataSources;
