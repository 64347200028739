import React, { createContext, useCallback, useReducer, useState, useMemo, useContext } from 'react';
import useAuth from 'auth/UseAuth';
import {
  GetPipelineFilters,
  PipelineFilters,
  SdfIssue,
  SdfStepType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from '../data/SuperDuperFiestaData';

interface IPipelineFiltersState {
  dataClasses: SuperDuperFiestaDataClass[];
  dataSources: SuperDuperFiestaDataSource[];
  entities: SuperDuperFiestaEntity[];
  issues: SdfIssue[];
  pipelines: SuperDuperFiestaPipeline[];
  stages: SuperDuperFiestaStage[];
  stepTypes: SdfStepType[];
}

interface IPipelineFiltersAction {
  type:
    | 'update_pipelines'
    | 'update_stages'
    | 'update_entities'
    | 'update_stepTypes'
    | 'update_issues'
    | 'update_dataClasses'
    | 'update_dataSources'
    | 'update_all'
    | 'reset';
  dataClasses?: SuperDuperFiestaDataClass[];
  dataSources?: SuperDuperFiestaDataSource[];
  entities?: SuperDuperFiestaEntity[];
  issues?: SdfIssue[];
  pipelines?: SuperDuperFiestaPipeline[];
  stages?: SuperDuperFiestaStage[];
  stepTypes?: SdfStepType[];
}

const initialFiltersState: IPipelineFiltersState = {
  dataClasses: [],
  dataSources: [],
  entities: [],
  issues: [],
  pipelines: [],
  stages: [],
  stepTypes: [],
};

export interface IUpdateValueProps {
  id: string;
  stepUpdateValueId: string | undefined;
  field: string | undefined;
  value: string | undefined;
  removed: boolean;
}

export const defaultUpdateValue = {
  stepUpdateValueId: undefined,
  field: '',
  value: '',
  removed: false,
};

export interface IRuleFormInput {
  executionOrder: number | null;
  ruleName: string;
  description: string;
  queryCondition: string;
  queryJoin: string;
  queryGroupBy: string;
  needsReview: boolean;
  active: boolean;
  stepUpdateValues: IUpdateValueProps[] | null;
  pipeline: SuperDuperFiestaPipeline | null;
  stepType: SdfStepType | null;
  entity: SuperDuperFiestaEntity | null;
  stage: SuperDuperFiestaStage | null;
  dataClass: SuperDuperFiestaDataClass | null;
  dataSources: SuperDuperFiestaDataSource[] | null;
  issue: SdfIssue | null;
}

export const defaultFormValues = {
  executionOrder: null,
  ruleName: '',
  description: '',
  queryCondition: '',
  queryJoin: '',
  queryGroupBy: '',
  needsReview: false,
  active: true,
  stepUpdateValues: [defaultUpdateValue],
  pipeline: null,
  stepType: null,
  entity: null,
  stage: null,
  dataSource: null,
  dataClass: null,
  issue: null,
};

function pipelineFiltersReducer(state: IPipelineFiltersState, action: IPipelineFiltersAction): IPipelineFiltersState {
  const { type } = action;

  switch (type) {
    case 'update_pipelines': {
      return { ...state, pipelines: action.pipelines ?? [] };
    }
    case 'update_stages': {
      return { ...state, stages: action.stages ?? [] };
    }
    case 'update_entities': {
      return { ...state, entities: action.entities ?? [] };
    }
    case 'update_stepTypes': {
      return { ...state, stepTypes: action.stepTypes ?? [] };
    }
    case 'update_issues': {
      return { ...state, issues: action.issues ?? [] };
    }
    case 'update_dataClasses': {
      return { ...state, dataClasses: action.dataClasses ?? [] };
    }
    case 'update_dataSources': {
      return { ...state, dataSources: action.dataSources ?? [] };
    }
    case 'update_all': {
      return { ...state, ...action };
    }
    case 'reset': {
      return { ...initialFiltersState };
    }
    default:
      return state;
  }
}

interface IFilteredState {
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
}

export interface IPipelineRulesContextData extends IFilteredState {
  pipelineFiltersState: IPipelineFiltersState;
  refreshData: boolean;
}

export interface IPipelineRulesContext extends IPipelineRulesContextData {
  getFilterData: () => void;
  pipelineFiltersDispatch: React.Dispatch<IPipelineFiltersAction>;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEntity: React.Dispatch<React.SetStateAction<SuperDuperFiestaEntity | null>>;
  setSelectedPipeline: React.Dispatch<React.SetStateAction<SuperDuperFiestaPipeline | null>>;
  setSelectedStage: React.Dispatch<React.SetStateAction<SuperDuperFiestaStage | null>>;
}

const PipelineRulesContext = createContext<IPipelineRulesContext>({
  getFilterData: () => {
    /* empty */
  },
  pipelineFiltersState: initialFiltersState,
  pipelineFiltersDispatch: () => {
    /* empty */
  },
  refreshData: false,
  setRefreshData: () => {
    /* empty */
  },
  selectedEntity: null,
  setSelectedEntity: () => {
    /* empty */
  },
  selectedPipeline: null,
  setSelectedPipeline: () => {
    /* empty */
  },
  selectedStage: null,
  setSelectedStage: () => {
    /* empty */
  },
});

export const PipelineRulesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { accessToken } = useAuth();

  const [pipelineFiltersState, pipelineFiltersDispatch] = useReducer(pipelineFiltersReducer, initialFiltersState);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const [selectedEntity, setSelectedEntity] = useState<SuperDuperFiestaEntity | null>(null);
  const [selectedPipeline, setSelectedPipeline] = useState<SuperDuperFiestaPipeline | null>(null);
  const [selectedStage, setSelectedStage] = useState<SuperDuperFiestaStage | null>(null);

  const queryParams = useMemo(
    () => ({
      includePipelines: pipelineFiltersState.pipelines.length === 0,
      includeStages: pipelineFiltersState.stages.length === 0,
      includeEntities: pipelineFiltersState.entities.length === 0,
      includeStepTypes: pipelineFiltersState.stepTypes.length === 0,
      includeIssues: pipelineFiltersState.issues.length === 0,
      includeDataClasses: pipelineFiltersState.dataClasses.length === 0,
      includeDataSources: pipelineFiltersState.dataSources.length === 0,
    }),
    [pipelineFiltersState]
  );

  const getFilterData = useCallback(async () => {
    if (!accessToken) return;

    try {
      // Prevent request if all filters are already populated
      if (Object.values(queryParams).every(value => !value)) {
        return;
      }

      const data: PipelineFilters = await GetPipelineFilters(queryParams, accessToken);

      if (data) {
        pipelineFiltersDispatch({ type: 'update_all', ...data });
      }
    } catch (error) {
      console.error('Error fetching filter data:', error);
    }
  }, [accessToken, queryParams]);

  const contextValue = useMemo(
    () => ({
      getFilterData,
      pipelineFiltersState,
      pipelineFiltersDispatch,
      refreshData,
      setRefreshData,
      selectedEntity,
      setSelectedEntity,
      selectedPipeline,
      setSelectedPipeline,
      selectedStage,
      setSelectedStage,
    }),
    [getFilterData, pipelineFiltersState, refreshData, selectedEntity, selectedPipeline, selectedStage]
  );

  return <PipelineRulesContext.Provider value={contextValue}>{children}</PipelineRulesContext.Provider>;
};

export const usePipelineRulesContext = () => {
  const context = useContext(PipelineRulesContext);
  if (!context) {
    throw new Error('usePipelineRulesContext must be used within a PipelineRulesContextProvider');
  }
  return context;
};

export default PipelineRulesContext;
